import {
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  Paper,
  TextField,
  useMediaQuery,
  Tooltip,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import footer from '../../../assets/background.png';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Radio from '@mui/material/Radio';
import { red } from '@mui/material/colors';
import { CustomButton } from '@tabletuck/components';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import { useDispatch, useSelector } from 'react-redux';
import { auth, firestore } from '../../../../../../libs/firebase-service/src';
import { getUserAddress } from '../../components/CartDrawer/utils';
import { setCart } from '../../../../../../libs/redux-slices/src';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { makeAPICall } from '../CartDrawer/utils.js';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const OrderSummary = ({
  typeTip,
  setTypeTip,
  otherTip,
  setOtherTip,
  setTipsAmount,
  tipAmount,
  delivery_method,
  defaultStore,
  defaultStorefee,
}) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedValue, setSelectedValue] = React.useState('b');
  const [open, setOpen] = React.useState(false);
  const token = useSelector((state) => state.authReducer.token);
  const cart = useSelector((state) => state.shopReducer.cart);
  const [data, setData] = useState([]);
  const [store, setStore] = React.useState([]);
  const [loading, setLoading] = useState([]);
  const [addressStore, setAddressStore] = useState([]);
  const [err, setErr] = useState('');
  const [fees, setFees] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [openDeliveryfees, setOpenDeliveryfees] = useState(false);

  const [opentaxes, setOpentaxes] = useState(false);
  const dispatch = useDispatch();
  const addresses = useSelector((state) => state.authReducer.userAddresses);
  const userData = useSelector((state) => state.authReducer.userData);
  const stores = useSelector((state) => state.shopReducer.stores);
  const user = useSelector((state) => state.authReducer.userData);
  const [dataStore, setDataStore] = useState([]);
  const {
    orderType,
    subTotal: total,
    discount,
    isVerify,
  } = useSelector((state) => state.shopReducer.cartData);
  const [serviceFees, setServiceFees] = useState(0);


  useEffect(() => {
    if (user?.pickupOrDelivery === 'dinein') {
      setServiceFees(dataStore?.service_fees_dineIn);
    } else if (user?.pickupOrDelivery === 'pickup') {
      setServiceFees(dataStore?.service_fees_pickUp);
    } else if (user?.pickupOrDelivery === 'delivery') {
      setServiceFees(dataStore?.service_fees);
    }
  }, [user]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      console.log(reason);
    } else {
      setOpen(false);
    }
  };
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const getUserAddresses = async () => {
    const bags = userData?.bagItems;
    bags.length && dispatch(setCart(bags));
    const defaultDeliveryAddress = userData.defaultDeliveryAddress;

    try {
      await Promise.all(
        addresses?.map(async (a) => {
          if (a?.id === defaultDeliveryAddress) {
            setAddressStore(a?.delivery_store_id);
            try {
              const fee = await makeAPICall(
                a?.delivery_store_id,
                a?.id,
                user?.uid
              );
              setDeliveryFee(fee);
            } catch (error) {
              // Handle specific error for makeAPICall
              console.error('Error in makeAPICall:', error);
            }
          }
        })
      );
    } catch (error) {
      console.error('Error in getUserAddresses:', error);
    }
  };

  useEffect(() => {
    getUserAddresses();
  }, [addressStore]);
  const getStores = () => {
    stores?.map((store) => {
      if (store?.id === addressStore) {
        setData(store);
      }

      if (store?.delivery_method === 'local') {
        setFees(store?.local_delivery_fees);
      } else setFees(store?.doordash_delivery_fees);

      setLoading(false);
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getStores();
  }, [addressStore]);

  const handleBackdropClick = () => {
    //these fail to keep the modal open
    event.stopPropagation();
    return false;
  };

  const getTips = () => {
    // handle case if user enter empty spaces
    let parsedTip = parseFloat(otherTip);
    if (!isNaN(parsedTip) && parsedTip >= 0) {
      setTipsAmount(parsedTip); // Update state with parsed tip value
    } else {
      parsedTip = 0; // Set tip amount to 0 in case of invalid input
      setTipsAmount(parsedTip); // Update state with 0
    }
    if (total <= 10) {
      if (typeTip === 'a') {
        return setTipsAmount(1), Math.floor(1);
      } else if (typeTip === 'b') {
        return setTipsAmount(2), Math.floor(2);
      } else if (typeTip === 'c') {
        return setTipsAmount(3), Math.floor(3);
      } else if (typeTip === 'other') {
        if (typeof otherTip === 'string' && otherTip?.trim() === '') {
          return total;
        } else return setTipsAmount(otherTip), parseFloat(otherTip);
      } else return total;
    } else if (total > 10) {
      if (typeTip === 'a') {
        return (
          setTipsAmount(Math.floor(0.1 * (total + fees))),
          Math.floor(0.1 * (total + fees))
        );
      } else if (typeTip === 'b') {
        return (
          setTipsAmount(Math.floor(0.2 * (total + fees))),
          Math.floor(0.2 * (total + fees))
        );
      } else if (typeTip === 'c') {
        return (
          setTipsAmount(Math.floor((total + fees) * 0.3)),
          Math.floor(0.3 * (total + fees))
        );
      } else if (typeTip === 'other') {
        if (otherTip === '') {
          return total;
        } else return setTipsAmount(otherTip), parseFloat(otherTip);
      } else return total;
    }
  };

  const getUser = async () => {
    const bags = userData?.bagItems;
    bags.length && dispatch(setCart(bags));
    const storeId = userData?.defaultStore;
    stores?.map((store) => {
      if (store?.id === storeId) {
        setStore(store?.restaurant_name);
      }
    });
  };
  useEffect(() => {
    getUser();
  }, []);
  const getStore = async () => {
    stores?.map((storee) => {
      if (storee?.restaurant_name === store) {
        setDataStore(storee);
      }
    });
  };
  useEffect(() => {
    getStore();
  }, [store, dataStore]);
  const [t, i18n] = useTranslation();

 
  return (
    <Paper
      style={{
           

       padding:  smDown ?'1rem 0.6rem 2rem 0.6rem' :'1.8rem 4.5rem 2rem 4.5rem' ,
        marginTop: smDown && 10,
      }}
    >
      {orderType !== 'dinein' ? (
        <Grid item style={{}} lg={12}>
          <Typography
            component="p"
            variant="h6"
            style={{ fontWeight: 'bold', marginTop: '20px' }}
          >
            {orderType === 'delivery'
              ? t('confirmPage.tips')
              : 'Reward your waiter with a tip'}
          </Typography>
          {total <= 10 ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '20px',
                marginBottom: '20px',
                flexDirection: 'row',
              }}
            >
              <Button
                disableRipple={true}
                style={{
                  backgroundColor:
                    typeTip === 'a' ? environment.mainColor : '#FBFAFA',
                  color: typeTip === 'a' ? 'white' : environment.mainColor,
                }}
                variant={'contained'}
                onClick={() => {
                  setTypeTip('a');
                }}
              >
                {' '}
                $1
              </Button>
              <Button
                disableRipple={true}
                style={{
                  backgroundColor:
                    typeTip === 'b' ? environment.mainColor : '#FBFAFA',
                  color: typeTip === 'b' ? 'white' : environment.mainColor,
                }}
                variant={'contained'}
                onClick={() => {
                  setTypeTip('b');
                }}
              >
                $2
              </Button>
              <Button
                disableRipple={true}
                style={{
                  backgroundColor:
                    typeTip === 'c' ? environment.mainColor : '#FBFAFA',
                  color: typeTip === 'c' ? 'white' : environment.mainColor,
                }}
                variant={'contained'}
                onClick={() => {
                  setTypeTip('c');
                }}
              >
                $3
              </Button>
              <Button
                disableRipple={true}
                style={{
                  backgroundColor:
                    typeTip === 'other' ? environment.mainColor : '#FBFAFA',
                  color: typeTip === 'other' ? 'white' : environment.mainColor,
                }}
                variant={'contained'}
                onClick={() => {
                  setTypeTip('other');
                  handleClickOpen();
                }}
              >
                {t('confirmPage.other')}
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '20px',
                marginBottom: '20px',
                flexDirection: 'row',
              }}
            >
              <Button
                disableRipple={true}
                style={{
                  backgroundColor:
                    typeTip === 'a' ? environment.mainColor : '#FBFAFA',
                  color: typeTip === 'a' ? 'white' : environment.mainColor,
                }}
                variant={'contained'}
                onClick={() => {
                  setTypeTip('a');
                }}
              >
                {' '}
                ${Math.floor(0.1 * (total + fees))}
              </Button>
              <Button
                disableRipple={true}
                style={{
                  backgroundColor:
                    typeTip === 'b' ? environment.mainColor : '#FBFAFA',
                  color: typeTip === 'b' ? 'white' : environment.mainColor,
                }}
                variant={'contained'}
                onClick={() => {
                  setTypeTip('b');
                }}
              >
                ${Math.floor(0.2 * (total + fees))}
              </Button>
              <Button
                disableRipple={true}
                style={{
                  backgroundColor:
                    typeTip === 'c' ? environment.mainColor : '#FBFAFA',
                  color: typeTip === 'c' ? 'white' : environment.mainColor,
                }}
                variant={'contained'}
                onClick={() => {
                  setTypeTip('c');
                }}
              >
                ${Math.floor(0.3 * (total + fees))}
              </Button>
              <Button
                disableRipple={true}
                style={{
                  backgroundColor:
                    typeTip === 'other' ? environment.mainColor : '#FBFAFA',
                  color: typeTip === 'other' ? 'white' : environment.mainColor,
                }}
                variant={'contained'}
                onClick={() => {
                  setTypeTip('other');
                  handleClickOpen();
                }}
              >
                {t('confirmPage.other')}
              </Button>
            </Box>
          )}

          <Typography component="p" variant="body2" style={{ color: 'gray' }}>
            {orderType === 'delivery' ? t('confirmPage.tipsDetails') : ''}
          </Typography>
        </Grid>
      ) : (
        ''
      )}
      <Typography component="p" variant="h5" style={{ fontWeight: 'bold' }}>
        {t('confirmPage.Ordersummary')}
      </Typography>
      <Divider />

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: '28px', marginBottom: '28px' }}
      >
        <Grid item xs={8} lg={8}>
          <Typography
            sx={{
              // ml: 2,
              flex: 1,
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
            variant="body1"
            component="p"
          >
            {t('basket.subtotal')}
          </Typography>
        </Grid>
        <Grid item xs={4} lg={4}>
          <Typography
            variant="h6"
            sx={{
              // mr: 2,
              flex: 1,
              color: 'gray',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
            }}
          >
            $
            {isVerify
              ? parseFloat((total - discount).toString()).toFixed(2)
              : parseFloat(total.toString()).toFixed(2)}
          </Typography>
        </Grid>
        {orderType === 'delivery' && (
          <>
            <Grid item xs={8} lg={6}>
              <Typography
                sx={{
                  // ml: 2,
                  flex: 1,
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
                variant="body1"
                component="p"
              >
                {t('basket.deliveryFees')}{' '}
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                 
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpenDeliveryfees(true)}
                />
                <Dialog
                  open={openDeliveryfees}
                  onClose={() => setOpenDeliveryfees(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {' '}
                    <Typography
                      component="p"
                      variant="h6"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      {t('basket.deliveryFees')}
                    </Typography>
                  </DialogTitle>
                  <Divider />
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t(
                        'basket.deliveryFeesAreCalculatedBasedOnYourLocationAndOtherFactors'
                      )}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDeliveryfees(false)}
                      color="primary"
                      style={{
                        backgroundColor: environment.mainColor,
                        color: 'white',
                      }}
                    >
                      {t('basket.oK')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Typography>
            </Grid>

            <Grid item  xs={4} lg={4}>
              <Typography
                component="a"
                variant="h6"
                sx={{
                  // mr: 2,
                  flex: 1,
                  color: 'gray',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
              >
                <p>
                  $
                  {parseFloat(
                    deliveryFee + total * data?.fee_miscellaneous
                  )?.toFixed(2)}
                </p>
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={8} lg={7}>
          <Typography
            sx={{
              // ml: 2,
              flex: 1,
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
            variant="body1"
            component="p"
          >
            {orderType === 'pickup' || orderType === 'dinein' ? (
              <span>Est. Taxes</span>
            ) : (
              <span>{t('basket.estTaxes')}</span>
            )}{' '}
            <InfoOutlinedIcon
              fontSize="small"
              sx={{
                cursor: 'pointer',
              }}
              style={{ direction: 'initial' }}
              onClick={() => setOpentaxes(true)}
            />
            <Dialog
              open={opentaxes}
              onClose={() => setOpentaxes(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {' '}
                <Typography
                  component="p"
                  variant="h6"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {orderType === 'pickup' || orderType === 'dinein' ? (
                    <span>{t('basket.estTaxesAndFees')}</span>
                  ) : (
                    <span>{t('basket.estTaxes')}</span>
                  )}
                </Typography>
              </DialogTitle>
              <Divider />
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {(orderType == 'pickup' || orderType == 'dinein') && (
                    <div>
                      <span style={{ color: 'black' }}>
                            {' '}
                            Service fee:{' '}
                            {serviceFees !== undefined && serviceFees !== null
                              ? `$${serviceFees.toFixed(2)}`
                              : 'N/A'}
                          </span>
                      <br />
                      {t(
                        'basket.thisServiceFeesHelpsUsOperateTheOnlineOrderingSystem'
                      )}
                      <br />
                    </div>
                  )}
                  <br />
                  <span style={{ color: 'black' }}>
                    {' '}
                    {t('basket.estTaxes')} : $
                    {dataStore?.percentage_type === 'amount' ? (
                      <>
                        {' '}
                        {orderType === 'dinein'
                          ? dataStore?.tax_percentage.toFixed(2)
                          : orderType === 'delivery'
                          ? data?.tax_percentage.toFixed(2)
                          : dataStore?.tax_percentage.toFixed(2)}
                      </>
                    ) : (
                      <>
                        {''}
                        {orderType === 'dinein'
                          ? (dataStore?.tax_percentage * total).toFixed(2)
                          : orderType === 'delivery'
                          ? (data?.tax_percentage * total).toFixed(2)
                          : (dataStore?.tax_percentage * total).toFixed(2)}
                      </>
                    )}{' '}
                  </span>
                  <br />
                  {t('basket.finalisedTaxWillBeShownOnYourOrderReceipt')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpentaxes(false)}
                  color="primary"
                  style={{
                    backgroundColor: environment.mainColor,
                    color: 'white',
                  }}
                >
                  {t('basket.oK')}
                </Button>
              </DialogActions>
            </Dialog>
          </Typography>
        </Grid>
        <Grid item xs={4} lg={4}>
          <Typography
            variant="h6"
            sx={{
              // mr: 2,
              flex: 1,
              color: 'gray',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
            }}
          >
            {orderType === 'dinein' && (
              <p>
             
                <span>
                  <span>
                    {defaultStorefee?.percentage_type === 'amount' ? (
                      <>
                        {''} $
                        {isVerify
                          ? parseFloat(
                              (
                                defaultStorefee.tax_percentage +
                                defaultStorefee.service_fees_dineIn
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                defaultStorefee.tax_percentage +
                                defaultStorefee.service_fees_dineIn
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        {''} $
                        {isVerify
                          ? parseFloat(
                              (
                                defaultStorefee.tax_percentage *
                                  (total - discount) +
                                defaultStorefee.service_fees_dineIn
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                defaultStorefee.tax_percentage * total +
                                defaultStorefee.service_fees_dineIn
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    )}
                  </span>
                </span>
              </p>
            )}
            {orderType === 'delivery' && (
              <p style={{ marginLeft: '5px' }}>
                <span>
                  {data.id === addressStore ? (
                    <span>
                      {data?.percentage_type === 'amount' ? (
                        <>
                          {''} $
                          {isVerify
                            ? parseFloat(
                                (data.tax_percentage * total)
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)
                            : parseFloat(
                                (data.tax_percentage * total)
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)}
                        </>
                      ) : (
                        <>
                          {''} $
                          {isVerify
                            ? parseFloat(
                                (data.tax_percentage * (total - discount))
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)
                            : parseFloat(
                                (data.tax_percentage * total)
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)}
                        </>
                      )}{' '}
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </p>
            )}
            {orderType === 'pickup' && (
              <p>
                <span>
                  <span>
                    {' '}
                    {defaultStorefee?.percentage_type === 'amount' ? (
                      <>
                        {''} $
                        {isVerify
                          ? parseFloat(
                              (
                                defaultStorefee.tax_percentage +
                                defaultStorefee.service_fees_pickUp
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                defaultStorefee.tax_percentage +
                                defaultStorefee.service_fees_pickUp
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        {''} $
                        {isVerify
                          ? parseFloat(
                              (
                                defaultStorefee.tax_percentage *
                                  (total - discount) +
                                defaultStorefee.service_fees_pickUp
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                defaultStorefee.tax_percentage * total +
                                defaultStorefee.service_fees_pickUp
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    )}{' '}
                  </span>
                </span>
              </p>
            )}
          </Typography>
        </Grid>

        {orderType != 'dinein' ? (
          <>
            {' '}
            <Grid item xs={7} lg={6}>
              <Typography
                sx={{
                  // ml: 2,
                  flex: 1,
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  marginRight:smDown?'70px':'0px'
                }}
                variant="body1"
                component="p"
              >
                {t('confirmPage.tip')}
               
              </Typography>
            </Grid>
            
            <Grid item xs={5} lg={4}>
              <Typography
                variant="h6"
                sx={{
                  // mr: 2,
                  flex: 1,
                  color: 'gray',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
              >
                $
                {parseFloat(tipAmount?.toString().replaceAll(',', '')).toFixed(
                  2
                )}
              </Typography>
            </Grid>{' '}
          </>
        ) : (
          ''
        )}
        <Grid item lg={12}>
          <Divider />
        </Grid>
        <Grid item xs={7} lg={6}>
          <Typography
            sx={{
              // ml: 2,
              flex: 1,
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
            variant="h6"
            component="div"
            style={{ color: environment?.mainColor }}
          >
            {t('basket.total')}
          </Typography>
        </Grid>
        <Grid item xs={5} lg={4}>
          <Typography
            variant="h6"
            style={{ direction: 'initial' }}
            sx={{
              // mr: 2,
              flex: 1,
              color: 'gray',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
              marginLeft: '35px',
            }}
          >
            {orderType === 'pickup' && (
              <div style={{ marginRight: '25px' }}>
                <p>
                  <p>
                    {' '}
                    {defaultStorefee?.percentage_type === 'amount' ? (
                      <>
                        {''} $
                        {isVerify
                          ? parseFloat(
                              (
                                getTips() +
                                defaultStorefee.tax_percentage +
                                defaultStorefee.service_fees_pickUp +
                                (total - discount)
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                getTips() +
                                defaultStorefee.tax_percentage +
                                defaultStorefee.service_fees_pickUp +
                                total
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        {''}$
                        {isVerify
                          ? parseFloat(
                              (
                                getTips() +
                                defaultStorefee.tax_percentage *
                                  (total - discount) +
                                defaultStorefee.service_fees_pickUp +
                                (total - discount)
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                getTips() +
                                defaultStorefee.tax_percentage * total +
                                defaultStorefee.service_fees_pickUp +
                                total
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    )}{' '}
                  </p>
                </p>
              </div>
            )}
            {orderType === 'dinein' && (
              <div>
                <p>
                  <p>
                    {' '}
                    {defaultStorefee?.percentage_type === 'amount' ? (
                      <>
                        {''} $
                        {isVerify
                          ? parseFloat(
                              (
                                defaultStorefee.tax_percentage +
                                defaultStorefee.service_fees_dineIn +
                                (total - discount)
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                defaultStorefee.tax_percentage +
                                defaultStorefee.service_fees_dineIn +
                                total
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        {''} $
                        {isVerify
                          ? parseFloat(
                              (
                                defaultStorefee.tax_percentage *
                                  (total - discount) +
                                defaultStorefee.service_fees_dineIn +
                                (total - discount)
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                defaultStorefee.tax_percentage * total +
                                defaultStorefee.service_fees_dineIn +
                                total
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    )}{' '}
                  </p>
                </p>
              </div>
            )}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              // mr: 2,
              flex: 1,
              color: 'gray',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
            }}
          >
            {orderType === 'delivery' && (
              <div>
                <p>
                  {data.id === addressStore ? (
                    <div>
                      {' '}
                      {data?.percentage_type === 'amount' ? (
                        <>
                          {''}$
                          {isVerify &&
                            parseFloat(
                              (
                                getTips() +
                                data.tax_percentage +
                                (total - discount) +
                                deliveryFee +
                                (total - discount * data?.fee_miscellaneous)
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                          {!isVerify &&
                            parseFloat(
                              (
                                data.tax_percentage +
                                total +
                                deliveryFee +
                                total * data?.fee_miscellaneous +
                                getTips()
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                        </>
                      ) : (
                        <>
                          {''}$
                          {isVerify &&
                            parseFloat(
                              (
                                getTips() +
                                data.tax_percentage * (total - discount) +
                                (total - discount) +
                                deliveryFee
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                          {!isVerify &&
                            parseFloat(
                              data.tax_percentage * total +
                                total +
                                deliveryFee +
                                total * data?.fee_miscellaneous +
                                getTips()
                            ).toFixed(2)}
                        </>
                      )}{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </p>
              </div>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Dialog
        onClose={handleClose}
        onBackdropClick={handleBackdropClick}
        disableEscapeKeyDown
        open={open}
        style={{ borderRadius: '250px' }}
        dir={i18n.language === 'ar' || i18n.language === 'he' ? 'rtl' : 'ltr'}
      >
        <div
          style={{
            padding: '15px 20px 0px 0px',
            display: 'flex',
            justifyContent: 'space-evenly',
            borderRadius: '25px',
          }}
        >
          {' '}
          <DialogTitle
            style={{
              display: 'grid',
              justifyContent: 'center',
            }}
          >
            <Typography
              component="p"
              variant="h6"
              style={{
                fontWeight: 'bold',
                color: 'rgb(160, 25, 19)',
              }}
            >
              {' '}
              {t('confirmPage.DILIVERYTIPS')}
            </Typography>
          </DialogTitle>
        </div>

        <DialogContent dividers>
          <Grid
            container
            style={{
              width: '100%',
              flexDirection: 'row',
            }}
          >
            <Grid item lg={12}>
              <TextField
                id="standard-basic"
                label={i18n.language === 'ar' ? '' : 'Add a Tip'}
                variant="outlined"
                value={otherTip}
                sx={{
                  '& label.Mui-focused': {
                    color: 'black',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'gray',
                    },
                  },
                }}
                error={otherTip < 0}
                style={{ width: '100%' }}
                onChange={(e) => setOtherTip(e.target.value)}
              />
            </Grid>
            <Grid item lg={12}>
              <Button
                onClick={() => {
                  if (parseFloat(otherTip) >= 0) {
                    setOtherTip(otherTip);
                    handleClose();
                  }
                }}
                style={{ color: environment?.mainColor }}
              >
                {' '}
                {t('profilee.save')}
              </Button>
              <Button
                onClick={() => {
                  setTypeTip('a');

                  handleClose();
                }}
                style={{ color: environment?.mainColor }}
              >
                {' '}
                {t('profilee.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default OrderSummary;
