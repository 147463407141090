import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  isLoggedIn: false,
  userData:[],
  user:[]
};

const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    loggedInSuccess: (state, action) => {
      state.token = action.payload.token;
      state.isLoggedIn = true;
    },
    setUser: (state, action) => {
      state.userData = action.payload.userData;
      state.user = action.payload.user;
    },
   
    updateUser: (state, action) => {
      state.userData = action.payload.userData;
      
    },
    userAddresses: (state, action) => {
      return {
        ...state,
        userAddresses: action.payload,
      }
    },
    setResEnv: (state, action) => {
      return {
        ...state,
        env: action.payload,
      }
    },
    loggedOutSuccess: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.userAddresses = null;
      state.userData = null;
      state.user = null
    },
    menues: (state, action) => {
      return {
        ...state,
        menues: action.payload,
      }
    },
    activeMenu: (state, action) => {
      return {
        ...state,
        activeMenu: action.payload,
      }
    },
    allactiveMenues: (state, action) => {
      return {
        ...state,
        allactiveMenues: action.payload,
      }
    },
    menuDocs: (state, action) => {
      return {
        ...state,
        menuDocs: action.payload,
      }
    },
    res: (state, action) => {
      return {
        ...state,
        res: action.payload,
      }
    },
  },
});

export const {
  loggedInSuccess,
  loggedOutSuccess,
  setUser,
  updateUser,
  userAddresses,
  setResEnv,
  menues,
  activeMenu,
  allactiveMenues,
  menuDocs,
  res


} = authSlice.actions;
export const authReducer = authSlice.reducer;
