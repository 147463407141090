import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Hidden,
} from '@mui/material';
import logo from '../../../assets/logoschool.png';
import alyousefLogo from '../../../assets/alyousef/alyousof-logo.png';
import logofilfil from '../../../assets/logofilfil.png';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { environment } from '../../../environments/environment';
import { useLocation, useNavigate } from 'react-router';
import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';
import { auth, firestore } from '../../../../../../libs/firebase-service/src';
import { useSelector } from 'react-redux';
import { CustomButton } from '../../../../../../libs/components/src';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Badge from '@mui/material/Badge';

import { loggedOutSuccess } from '@tabletuck/redux-slices';
import CartDrawer from '../CartDrawer/CartDrawer';
import {
  loggedInSuccess,
  setUser,
} from '../../../../../../libs/redux-slices/src';

const SchoolHeader = () => {
  const navigate = useNavigate();
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const userData = useSelector((state) => state.authReducer.userData);
  const stores = useSelector((state) => state.shopReducer.stores);
  const cart = useSelector((state) => state.shopReducer.cart);
  const [t, i18n] = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  const [openCartDialoge, setOpenCartDialoge] = React.useState(false);
  const [defaultStoreAdress, setDefaultStoreAdress] = useState('');
  useEffect(() => {
    stores?.filter((store) =>
      store.id === userData?.defaultStore
        ? setDefaultStoreAdress(store?.address?.displayName)
        : ''
    );
  }, [defaultStoreAdress]);
  const getCount = () => {
    let count = 0;
    for (let index = 0; index < cart.length; index++) {
      const element = cart[index];
      count = count + element?.quantity;
    }
    setCounter(count);
  };
  useEffect(() => {
    getCount();
  }, [cart, auth?.currentUser?.uid]);
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const res = await firestore.collection('users').doc(user?.uid).get();
        if (res.exists) {
          const data = res.data();
          if (data?.email && data.fullName) {
            dispatch(loggedInSuccess({ token: user.uid }));
            dispatch(setUser({ user: user, userData: data }));
          }
        }
      }
    });
  }, [auth, window.location.pathname]);
  const signOut = async () => {
    try {
      await auth.signOut();
      dispatch(loggedOutSuccess());
      dispatch(setCart([]));
      navigate('/');
      localStorage.clear();
    } catch (e) {
      console.log(e.message);
    }
    navigate('/');
  };

  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'signup':
        return licenses?.frontend?.signup;
      case 'profile':
        return licenses?.frontend?.profile;
      case 'checkout':
        return licenses?.frontend?.checkout;

      default:
        return false;
    }
  };
  const handleClickOpenCartDialoge = () => {
    setOpenCartDialoge(true);
  };
  const handleCloseCartDialoge = () => {
    setOpenCartDialoge(false);
  };

  return (
    <AppBar
      position="absolute"
      style={{
        backgroundColor:
          location.pathname == '/' ||
            location.pathname == '/menu' ||
            location.pathname == '/confirmOrder'
            ? 'transparent'
            : environment.restaurantId == 'alyousef'
              ? 'black'
              : environment.restaurantId == 'filfil'
                ? 'black'
                : environment.mainColor,

        boxShadow: 'none',
      }}
    >
      <Toolbar>
        {/* Logo on the right */}
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          <img
            src={
              environment.restaurantId === 'alyousef'
                ? alyousefLogo
                : environment.restaurantId === 'filfil'
                  ? logofilfil
                  : logo
            }
            alt="logo"
            style={{ width: '80px', height: '70px', cursor: 'pointer' }}
            onClick={() => navigate('/')}
          />
        </Typography>

        {/* Menu items in a row */}
        <Button
          color="inherit"
          style={{
            fontWeight: 'bold',
            color:
              location.pathname == '/menu'
                ? environment?.primaryColor
                : 'white',
          }}
          onClick={() => navigate('/menu')}
        >
          Menu
        </Button>
        <Hidden smDown>
          <>
            <Button
              color="inherit"
              style={{
                fontWeight: 'bold',
                color:
                  location.pathname == '/careers'
                    ? environment?.primaryColor
                    : 'white',
              }}
              onClick={() => navigate('/careers')}
            >
              Careers
            </Button>

            {/* Contact */}
            <Button
              color="inherit"
              style={{
                fontWeight: 'bold',
                color:
                  location.pathname == '/contactus'
                    ? environment?.primaryColor
                    : 'white',
              }}
              onClick={() => navigate('/contactus')}
            >
              Contact
            </Button>
          </>
        </Hidden>

        {/* Vertical line */}
        <div
          style={{
            borderLeft: `1.5px solid ${environment.primaryColor}`,
            height: '30px',
            margin: '0 10px',
          }}
        ></div>

        {/* Basket icon */}
        {auth?.currentUser?.uid && (
          <>
            {checkLicenses('checkout') &&
              auth?.currentUser?.uid != undefined && (
                <>
                  <CartDrawer
                    openCartDialoge={openCartDialoge}
                    handleCloseCartDialoge={handleCloseCartDialoge}
                    userData={userData}
                    defaultStoreAdress={defaultStoreAdress}
                    setDefaultStoreAdress={setDefaultStoreAdress}
                  />
                  {counter > 0 ? (
                    <Badge
                      badgeContent={counter > 0 ? counter : ''}
                      sx={{
                        '& .MuiBadge-badge': {
                          color: 'white',
                          backgroundColor: environment.mainColor,
                        },
                      }}
                      style={{
                        marginBottom: '1pc',
                        background: 'radial-gradient(black, transparent)',
                      }}
                    ></Badge>
                  ) : (
                    ''
                  )}

                  <Hidden smUp>
                    <ShoppingCartIcon
                      sx={{
                        color: 'white',
                        width: '10%',
                        display: 'flex',
                        borderRadius: '30px',
                        height: '35px',
                        backgroundColor: environment.mainColor,
                        padding: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleClickOpenCartDialoge}
                    />
                  </Hidden>
                  <Hidden smDown>
                    <ShoppingCartIcon
                      sx={{
                        color: 'white',
                        width: '3%',
                        display: 'flex',
                        borderRadius: '30px',
                        height: '35px',
                        backgroundColor: environment.mainColor,
                        padding: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleClickOpenCartDialoge}
                    />
                  </Hidden>

                  {/* </IconButton> */}
                </>
              )}

            {/* Profile icon */}

            {checkLicenses('profile') && (
              <>
                <div
                  style={{
                    border: '6px solid white',
                    background: 'white',
                    borderRadius: '20px',
                    marginRight: '14px',
                    marginLeft: '10px',
                    boxShadow:
                      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  }}
                >
                  <PersonOutlineSharpIcon
                    style={{
                      color: environment.primaryColor,
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/profile')}
                  />
                </div>
              </>
            )}

            <IconButton color="inherit">
              <ExitToAppIcon
                onClick={signOut}
                style={{
                  transform: i18n.language === 'ar' ? 'scaleX(-1.0)' : 'none',
                  color: environment.primaryColor,
                }}
              />
            </IconButton>
          </>
        )}
        {!auth?.currentUser?.uid && checkLicenses('signup') && (
          <>
            <Hidden smUp>
              <CustomButton
                bg={environment.primaryColor}
                onClick={() => navigate('/signup')}
                buttonText={t('Joinus')}
              />
            </Hidden>
            <Hidden smDown>
              <CustomButton
                bg={environment.primaryColor}
                onClick={() => navigate('/signup')}
                buttonText={t('Joinus')}
              />
            </Hidden>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default SchoolHeader;
