import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import HomeIcon from '@mui/icons-material/Home';
import AddressHandlerDialog from './AddressHandler';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import { useSelector } from 'react-redux';
import { firestore } from '../../../../../../libs/firebase-service/src';
import WorkIcon from '@mui/icons-material/Work';
import DeleteIcon from '@mui/icons-material/Delete';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { useDispatch } from 'react-redux';
import { userAddresses } from '../../../../../../libs/redux-slices/src';
import { useTranslation } from 'react-i18next';

const Address = () => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const adresses = useSelector((state) => state.authReducer.userAddresses);
  const [adressess, setAdresses] = useState([]);
  const token = useSelector((state) => state.authReducer.token);
  const [adressToDelete, setAdressToDelete] = useState('');
  const dispatch = useDispatch()

  const handleClosee = () => {
    setOpenDelete(false);
  };

  const deleteAdress = async () => {

    if (adressToDelete) {
      const docRef = firestore
        .collection('users')
        .doc(token)
        .collection('addresses')
        .doc(adressToDelete);
      await docRef.delete().catch((e) => {
        console.log(e);
      }).then(() => {
        const indexToDelete = adresses.findIndex((obj) => obj.id === adressToDelete);
        if (indexToDelete !== -1) {
          const newArray = adresses
            .slice(0, indexToDelete)
            .concat(adresses.slice(indexToDelete + 1));
          dispatch(userAddresses(newArray));
        } else {
          console.log("Object not found");
        }
      })

    }
    setOpenDelete(false);
    setAdressToDelete('');
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [t, i18n] = useTranslation()

  return (
    <div
      className="mt-3.5"
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
    >
      <div>
        <Button
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
          variant="text"
          style={{
            width: '100%',
            boxShadow:
              '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            color: environment.mainColor,
            padding: '10px',
            borderRadius: '20px',
            fontWeight: 'bold',
          }}
          onClick={handleClickOpen}
        >
          {' '}
          <AddLocationAltIcon /> {t("profilee.addNewAddress")}
        </Button>
      </div>
      <AddressHandlerDialog open={open} handleClose={handleClose} />

      {adresses?.map((c) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div class="flex flex-col md:flex-row md:max-w-xl rounded-lg mt-3">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {c.nickName === 'Home' ? (
                <HomeIcon
                  fontSize="large"
                  style={{
                    color: 'white',
                    backgroundColor: environment.mainColor,
                    width: '50px',
                    height: '50px',
                    borderRadius: '5px',
                  }}
                />
              ) : c.nickName === 'Work' ? (
                <WorkIcon
                  fontSize="large"
                  style={{
                    color: 'white',
                    backgroundColor: environment.mainColor,
                    width: '50px',
                    height: '50px',
                    borderRadius: '5px',
                  }}
                />
              ) : (
                <AutoStoriesIcon
                  fontSize="large"
                  style={{
                    color: 'white',
                    backgroundColor: environment.mainColor,
                    width: '50px',
                    height: '50px',
                    borderRadius: '5px',
                  }}
                />
              )}
            </div>

            <div class="p-2 flex flex-col justify-start">
              <h5 class="text-gray-900 text-xl font-medium ">{c.nickName}</h5>
              <p class="text-gray-700 text-base ">{c.displayName}</p>
            </div>
            <Dialog
              style={{ zIndex: 10 }}
              open={openDelete}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
              dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}

            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {t("profilee.youWantDeleteAdress")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  style={{ color: environment.mainColor }}
                  onClick={handleClosee}
                >
                  {t("profilee.cancel")}
                </Button>
                <Button
                  style={{ color: environment.mainColor }}
                  onClick={() => deleteAdress()}
                >
                  {t("profilee.delete")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DeleteIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setAdressToDelete(c.id);
                setOpenDelete(true);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Address;
