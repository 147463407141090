import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Hidden, IconButton } from '@mui/material';
import { CustomButton } from '@tabletuck/components';
import FulPageDialoge from '../../../../../apps/table-tuck-web/src/app/components/Dialouge/FullpageDialoge';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CartDrawer from '../../../../../apps/table-tuck-web/src/app/components/CartDrawer/CartDrawer';
import { auth, firestore } from '@tabletuck/firebase-services';
import { useDispatch, useSelector } from 'react-redux';
import LoginHeader from './loginHeader';
import { useTranslation } from 'react-i18next';
import {
  loggedOutSuccess,
  setCart,
  setUser,
  loggedInSuccess,
  activeMenu,
  allactiveMenues,
  menues,
  setStores,
  
} from '@tabletuck/redux-slices';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useParams } from 'react-router';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router';
import logo from '../../../../../apps/table-tuck-web/src/assets/white-logo.png';
import blacklogo from '../../../../../apps/table-tuck-web/src/assets/blacklogo.png';
import TTdemoLogo from '../../../../../apps/table-tuck-web/src/assets/TTdemoLogo.png';
import schoolLogo from '../../../../../apps/table-tuck-web/src/assets/logoschool.png';
import alyousefLogo from '../../../../../apps/table-tuck-web/src/assets/alyousef/alyousof-logo.png';

import MailIcon from '@mui/icons-material/Mail';
import { styled, alpha } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Dialog } from '@mui/material';
import { RootFirestore } from '@tabletuck/firebase-services';
import SettingsIcon from '@mui/icons-material/Settings';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Formik } from 'formik';
import { setDefaultStore } from 'apps/table-tuck-web/src/app/components/CartDrawer/utils';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export function Header(props) {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const cart = useSelector((state) => state.shopReducer.cart);
  const token = useSelector((state) => state.authReducer.token);
  const userData = useSelector((state) => state.authReducer.userData);
  const currentUser = auth?.currentUser;
  const res = useSelector((state) => state.authReducer.res);

  // const [userData, setUserData] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const location = useLocation();
  const [counter, setCounter] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = anchorEl;
  const [env, setEnv] = useState([]);
  const stores = useSelector((state) => state.shopReducer.stores);
  const [store, setStore] = React.useState('');
  console.log(stores, 'stores')
  const getStrores = async () => {
    try {
      let storeRef = firestore.collection('stores');
      let list = [];
      const StoresList = await storeRef.get();
      for (let index = 0; index < StoresList?.docs?.length; index++) {
        let element = StoresList?.docs[index]?.data();
        element.id = StoresList?.docs[index]?.id;
        list.push(element);
      }
      dispatch(setStores(list));
      console.log(list,'list')
      setStore(list);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(()=>{
    getStrores()
  },[])


  const menuDocs = useSelector((state) => state.authReducer.menuDocs);
  const [defaultStoreAdress, setDefaultStoreAdress] = useState('');
  useEffect(() => {
    stores?.filter((store) =>
      store.id === userData?.defaultStore
        ? setDefaultStoreAdress(store?.address?.displayName)
        : ''
    );
  }, [defaultStoreAdress]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const res = await firestore.collection('users').doc(user?.uid).get();
        if (res.exists) {
          const data = res.data();
          if (data?.email && data.fullName) {
            dispatch(loggedInSuccess({ token: user.uid }));
            dispatch(setUser({ user: user, userData: data }));
          }
        }
      }
    });
  }, [auth, window.location.pathname]);
  const getENV = async () => {
    const doc = await RootFirestore.collection('environment')
      .doc(props.env.restaurantId)
      .get();
    setEnv(doc.data());
  };

  useEffect(() => {
    getENV();
  }, []);

  const handleSubmit = async (values) => {
    await RootFirestore.collection('environment')
      .doc(props.env.restaurantId)
      .set(
        {
          paymentFlow: values?.paymentFlow,
          orderFlow: values?.orderFlow,
        },
        { merge: true }
      )
      .catch((err) => {
        console.warn('success', err);
      });
    setOpenDialog(false);
  };

  const getCount = () => {
    let count = 0;
    for (let index = 0; index < cart?.length; index++) {
      const element = cart[index];
      count = count + element?.quantity;
    }
    setCounter(count);
  };
  useEffect(() => {
    getCount();
  }, [cart, auth?.currentUser?.uid, token]);
  const check = window.localStorage.getItem('items');

  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'categories':
        return licenses.dashboard.categories;
      case 'signup':
        return false;
      case 'profile':
        return  false;
      case 'checkout':
        return  false;
      case 'items':
        return licenses.dashboard.items;
      case 'drivers':
        return licenses.dashboard.drivers;
      case 'modifierGroup':
        return licenses.dashboard.modifierGroup;
      case 'admins':
        return licenses.dashboard.admins;
      case 'stores':
        return licenses.dashboard.stores;
      case 'offers':
        return licenses.dashboard.offers;
      case 'orderHistory':
        return licenses.dashboard.orderHistory;

      default:
        return false;
    }
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      dispatch(loggedOutSuccess());
      dispatch(setCart([]));
      navigate('/');
      localStorage.clear();
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openCartDialoge, setOpenCartDialoge] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleClickOpenCartDialoge = () => {
    setOpenCartDialoge(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const handleCloseCartDialoge = () => {
    setOpenCartDialoge(false);
  };
  // if (props.isAdmin && !auth?.currentUser?.uid)
  //   return (
  //     <LoginHeader color={props.env.mainColor} title={props.env.headerTitle} />
  //   );
  const [t, i18n] = useTranslation();

  return (
    licenses && (
      <AppBar
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
        position="absolute"
        style={{
          backgroundColor:
            props.env.restaurantId === 'taqwasbakery' ||
            props.env.restaurantId === 'elmbrookschools' ||
            props.env.restaurantId === 'alyousef' ||
            props.env.restaurantId === 'filfil' ||
            props.env.restaurantId === 'ttDemo'
              ? 'transparent'
              : 'transparent',
          boxShadow:
            props.location === '/'
              ? 'none'
              : '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <div sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />

            <Typography
              onClick={() => navigate('/')}
              noWrap
              component="a"
              style={{ display: 'flex', cursor: 'pointer' }}
              variant="h6"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 700,
                color: 'black',
              }}
            >
              {props.env.restaurantId === 'taqwasbakery' ? (
                <>
                  {props.location === '/menu-items' ? (
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '4rem',
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {/* {props.location === '/confirmorder' ? (
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '4rem',
                      }}
                    />
                  ) : (
                    ''
                  )} */}
                  {props.location === '/' ? (
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '4rem',
                      }}
                    />
                  ) : (
                    <>
                      {props.location != '/menu-items' ? (
                        <img
                          src={blacklogo}
                          alt="logo"
                          style={{
                            display: 'flex',
                            cursor: 'pointer',
                            width: '4rem',
                          }}
                        />
                      ) : (
                        props.location != '/menu-items' && (
                          <img
                            src={logo}
                            alt="logo1"
                            style={{
                              display: 'flex',
                              cursor: 'pointer',
                              width: '4rem',
                            }}
                          />
                        )
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {props.env.restaurantId === 'taqwasbakery' &&
                  props.isAdmin ? (
                    <img
                      src={blacklogo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '4rem',
                      }}
                    />
                  ) : props.env.restaurantId === 'taqwasbakery' ? (
                    props.title
                  ) : props.env.restaurantId === 'ttDemo' ? (
                    <img
                      src={TTdemoLogo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '5rem',
                      }}
                    />
                  ) : props.env.restaurantId === 'elmbrookschools' ? (
                    <img
                      src={schoolLogo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '5rem',
                      }}
                    />
                  ) : (
                    <h2 style={{ color: 'white' }}>{props.title}</h2>
                  )}
                </>
              )}
            </Typography>

            {props.isAdmin ? (
              <>
                <Hidden smUp>
                  <>
                    <IconButton
                      styly={{
                        backgroundColor: 'white',
                        marginLeft: '10px',
                      }}
                      aria-label="open drawer"
                      edge="end"
                      onClick={handleClickOpenDrawer}
                    >
                      <MenuIcon
                        style={{
                          color: 'rgb(160, 25, 19)',
                          marginLeft: '10px',
                        }}
                      />
                    </IconButton>
                  </>
                </Hidden>
              </>
            ) : (
              <>
                {props.env.restaurantId !== 'istanbulDk' && (
                  <>
                    <div>
                      <Hidden smDown>
                        <Button
                          id="demo-customized-button"
                          sx={{
                            background: props.env.mainColor,
                            borderRadius: '24px',
                            color: 'white',
                          }}
                          aria-controls={
                            openMenu ? 'demo-customized-menu' : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openMenu ? 'true' : undefined}
                          style={{ fontWeight: 'bold' }}
                          onClick={handleClick}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          {t('contact')}
                        </Button>
                      </Hidden>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem
                          onClick={() => {
                            navigate('/contactus');
                            handleCloseMenu();
                          }}
                          disableRipple
                        >
                          {t('contact')}
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          onClick={() => {
                            navigate('/careers');
                            handleCloseMenu();
                          }}
                          disableRipple
                        >
                          {t('career')}
                        </MenuItem>
                        <Divider />
                        {props.env.restaurantId === 'taqwasbakery' ||
                          (props.env.restaurantId === 'alyousef' && (
                            <MenuItem
                              onClick={() => {
                                navigate('/aboutus');
                                handleCloseMenu();
                              }}
                              disableRipple
                            >
                              About us
                            </MenuItem>
                          ))}
                      </StyledMenu>
                    </div>

                    <Button
                      disableRipple={true}
                      disableFocusRipple={true}
                      sx={{ my: 2, display: 'block' }}
                      disabled={open}
                    >
                      {store &&
                        store?.map((c) => (
                          <CustomButton
                            bg={props.env.mainColor}
                            onClick={async () => {
                              // setDefaultStore(
                              //   token,
                              //   c.id,
                              //   c?.restaurant_name,
                              //   setStore,
                              //   c?.pickup_enabled,
                              //   c?.pickup_enabled,
                              //   c.dinein_enable,
                              //   c.dinein_enable,
                              //   c.address.displayName,
                              //   c?.address?.displayName
                              // );
                              // if (
                              //   userData?.bagItems?.length > 0 &&
                              //   cart?.length > 0
                              // ) {
                              //   dispatch(checkStore(true));
                              // } else dispatch(checkStore(false));

                              // onClickItem(item.restaurant_name)
                              handleClose();
                              const updatedUser = {
                                ...userData,
                                defaultStore: c.id,
                              };
                            navigate('/menu');
                              dispatch(setUser({ userData: updatedUser }));
                              dispatch(activeMenu(res[c?.id]?.active_menu_now));
                              dispatch(
                                allactiveMenues(res[c?.id]?.all_active_menus)
                              );
                              dispatch(menues(res[c&& c?.id]?.menus));
                             
                            }}
                            buttonText={t('ourMenu')}
                          />
                        ))}
                    </Button>
                  </>
                )}
                <Hidden smDown>
                  {props.env.restaurantId === 'istanbulDk' && (
                    <>
                      <div
                        style={{
                          display: mdDown && 'none',
                        }}
                      >
                        <CustomButton
                          bg={props.env.mainColor}
                          onClick={handleClickOpen}
                          buttonText={t('ourMenu')}
                        />
                      </div>

                      <FulPageDialoge open={open} handleClose={handleClose} />
                    </>
                  )}
                </Hidden>
                {!props.isAdmin &&
                  auth?.currentUser?.uid &&
                  checkLicenses('profile') && (
                    <>
                      <div
                        style={{
                          border: '6px solid white',
                          background: 'white',
                          borderRadius: '20px',
                          marginRight: '14px',
                          marginLeft: '10px',
                          boxShadow:
                            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        }}
                      >
                        <PersonOutlineSharpIcon
                          style={{
                            color: props.env.mainColor,
                            cursor: 'pointer',
                          }}
                          onClick={() => navigate('/profile')}
                        />
                      </div>
                    </>
                  )}
              </>
            )}
            <div>
              {!props.isAdmin &&
              !auth?.currentUser?.uid ?
               (
                // <Button
                //   disableRipple={true}
                //   disableFocusRipple={true}
                //   sx={{ my: 2, display: 'block' }}
                //   disabled={open}
                // >
                <>
                {
                  console.log(checkLicenses('signup') , 'sjsjj')
                }
                {checkLicenses('signup') ? <> <Hidden smUp>
                    <CustomButton
                      bg={props.env.mainColor}
                      onClick={() => navigate('/signup')}
                      buttonText={t('Joinus')}
                    />
                  </Hidden>
                  <Hidden smDown>
                    <CustomButton
                      bg={props.env.mainColor}
                      onClick={() => navigate('/signup')}
                      buttonText={t('signIn') + '/' + t('Joinus')}
                    />
                  </Hidden></>:''}
                 
                </>
              ) : (
                // </Button>
                ''
              )}
            </div>

            {!props.isAdmin &&
              auth?.currentUser?.uid &&
              checkLicenses('checkout') && (
                <>
                  <CartDrawer
                    openCartDialoge={openCartDialoge}
                    handleCloseCartDialoge={handleCloseCartDialoge}
                    addresses={addresses}
                    userData={userData}
                    defaultStoreAdress={defaultStoreAdress}
                    setDefaultStoreAdress={setDefaultStoreAdress}
                  />
                  {counter > 0 ? (
                    <Badge
                      badgeContent={counter > 0 ? counter : ''}
                      sx={{
                        '& .MuiBadge-badge': {
                          color: 'white',
                          backgroundColor: props.env.mainColor,
                        },
                      }}
                      style={{
                        marginBottom: '1pc',
                        background: 'radial-gradient(black, transparent)',
                      }}
                    ></Badge>
                  ) : (
                    ''
                  )}

                  <Hidden smUp>
                    <ShoppingCartIcon
                      sx={{
                        color: 'white',
                        width: '10%',
                        display: 'flex',
                        borderRadius: '30px',
                        height: '35px',
                        backgroundColor: props.env.mainColor,
                        padding: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleClickOpenCartDialoge}
                    />
                  </Hidden>
                  <Hidden smDown>
                    <ShoppingCartIcon
                      sx={{
                        color: 'white',
                        width: '3%',
                        display: 'flex',
                        borderRadius: '30px',
                        height: '35px',
                        backgroundColor: props.env.mainColor,
                        padding: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleClickOpenCartDialoge}
                    />
                  </Hidden>

                  {/* </IconButton> */}
                </>
              )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Formik
                initialValues={{
                  paymentFlow: '',
                  orderFlow: '',
                }}
                onSubmit={handleSubmit}
                // validationSchema={validateSchema}
              >
                {({
                  handleBlur,
                  handleChange,
                  values,
                  errors,
                  handleSubmit,
                  touched,
                }) =>
                  env && (
                    <Dialog
                      style={{ zIndex: 10 }}
                      open={openDialog}
                      keepMounted
                      onClose={() => {
                        setOpenDialog(false);
                      }}
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <div style={{ height: '200px', width: '200px' }}>
                          <Box sx={{ minWidth: 120 }}>
                            <div style={{ height: '20px' }} />
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Payment flow
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={env?.paymentFlow}
                                value={env?.paymentFlow}
                                label="Payment flow"
                                name={'paymentFlow'}
                                onChange={handleChange('paymentFlow')}
                              >
                                <MenuItem value={'clover'}>Clover</MenuItem>
                                <MenuItem value={'stripe'}>Stripe</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <div style={{ height: '30px' }} />
                          <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Order flow
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={env?.orderFlow}
                                defaultValue={env?.orderFlow}
                                label="Order flow"
                                name={'orderFlow'}
                                onChange={handleChange('orderFlow')}
                              >
                                <MenuItem value={'tablet'}>
                                  Kitchen app
                                </MenuItem>
                                <MenuItem value={'pos'}>Point of sale</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          style={{ color: props.env.mainColor }}
                          onClick={() => {
                            setOpenDialog(false);
                          }}
                        >
                          cancel
                        </Button>
                        <Button
                          style={{ color: props.env.mainColor }}
                          onClick={handleSubmit}
                        >
                          save
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )
                }
              </Formik>
              {auth?.currentUser?.uid && props?.isAdmin && env && (
                <Tooltip
                  title="App setting"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <SettingsIcon
                    style={{
                      color: props.env.mainColor,
                      marginLeft: '5px',
                      cursor: 'pointer',
                      height: 30,
                    }}
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                  />
                </Tooltip>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {auth?.currentUser?.uid &&
                !props.isAdmin &&
                checkLicenses('signup') && (
                  <Tooltip
                    title="Log out"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ExitToAppIcon
                      style={{
                        color:
                          props.env.restaurantId === 'ttDemo'
                            ? props.env.mainColor
                            : props.env.restaurantId != 'taqwasbakery' ||
                              props.env.restaurantId != 'alyousef'
                            ? props.env.mainColor
                            : location.pathname == '/' ||
                              location.pathname == '/menu-items' ||
                              location.pathname == '/careers' ||
                              location.pathname == '/contactus'
                            ? 'white'
                            : props.env.mainColor,
                        marginLeft: '5px',
                        cursor: 'pointer',
                        height: 30,
                        transform:
                          i18n.language === 'ar' ? 'scaleX(-1.0)' : 'none',
                      }}
                      onClick={signOut}
                    />
                  </Tooltip>
                )}
              {auth?.currentUser?.uid && props?.isAdmin && (
                <Tooltip
                  title="Log out"
                  dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ExitToAppIcon
                    style={{
                      color: props.env.mainColor,
                      marginLeft: '5px',
                      cursor: 'pointer',
                      height: 30,
                    }}
                    onClick={signOut}
                  />
                </Tooltip>
              )}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    )
  );
}
export default Header;
