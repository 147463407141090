import { Button, Divider, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import backgroundHomeSchool from '../../../assets/backgroundhomeschool.png';
import footer from '../../../assets/background.png';
import { environment } from '../../../environments/environment';
import homet from '../../../assets/homt.jpg';
import aboutAlyousef from '../../../assets/alyousef/about.png';
import hometabletuck from '../../../assets/TTdemoHome.png';
import aboutSchool from '../../../assets/aboutschool.png';
import sigschool from '../../../assets/sigschool.png';
import aboutFilfil from '../../../assets/aboutfilfil.png';

function AboutSection() {
  const [t] = useTranslation();

  return (
    <div
      style={{
        position: 'relative',
        backgroundImage:
          environment.restaurantId === 'elmbrookschools' ||
          environment.restaurantId === 'alyousef' ||
          environment.restaurantId === 'filfil'
            ? `url(${backgroundHomeSchool})`
            : `url(${footer})`,
        paddingTop: '90px',
        paddingBottom: '50px',
      }}
    >
      <Container maxWidth={'xl'}>
        <Grid
          container
          spacing={1}
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {t('aboutHomeDetails') && (
            <>
              {environment.restaurantId !== 'elmbrookschools' ? (
                <Grid
                  item
                  lg={6}
                  sm={12}
                  style={{ alignItems: 'center', marginTop: '10px' }}
                >
                  <Typography
                    variant="h2"
                    style={{
                      color: environment.mainColor,
                      fontWeight: 'bold',
                      fontFamily: `Hanalei Fill, cursive`,
                    }}
                    sx={{
                      fontSize: { xs: '40px', md: '60px', lg: '40px' },
                    }}
                  >
                    {environment.restaurantId === 'ttDemo'
                      ? t('aboutHome')
                      : environment.restaurantId === 'alyousef'
                      ? 'About Alyousef'
                      : environment.restaurantId === 'filfil'
                      ? 'About Filfil'
                      : t('aboutTaqwa')}
                  </Typography>
                  <Grid
                    item
                    xs={4}
                    direction="row"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <span
                      className="dot"
                      style={{ backgroundColor: environment.mainColor }}
                    ></span>
                    <Divider
                      style={{
                        marginBottom: '5px',
                        marginTop: '5px',
                        height: '2px',
                        backgroundColor: environment.mainColor,
                        width: '50%',
                        marginLeft: '8px',
                      }}
                    />
                  </Grid>
                  <Typography
                    variant="body1"
                    style={{
                      color: 'black',
                    }}
                  >
                    {environment.restaurantId === 'alyousef'
                      ? `At Al-Yousef Restaurant, we take pride in serving delicious meals made with love and authentic flavors. Our menu offers a wide variety of dishes that will satisfy any craving, ensuring a memorable dining experience for all.`
                      : environment.restaurantId === 'filfil'
                      ? `A chef is a highly trained and skilled professional cook who is proficient in all aspects of food preparation of a particular cuisine. The word "chef" is derived from the term chef de cuisine, the director or head of a kitchen.`
                      : environment.restaurantId === 'taqwasbakery'
                      ? environment?.about
                      : t('aboutHomeDetails')}
                  </Typography>
                </Grid>
              ) : (
                <Grid item lg={6} sm={12} style={{ alignItems: 'center' }}>
                  <Grid
                    item
                    xs={4}
                    direction="row"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Divider
                      style={{
                        height: '2px',
                        backgroundColor: environment.primaryColor,
                        width: '38%',
                      }}
                    />
                  </Grid>
                  <Typography
                    variant="body1"
                    style={{
                      color: environment.mainColor,
                    }}
                  >
                    {t('aboutSchool')}
                  </Typography>
                  <Grid
                    item
                    xs={4}
                    direction="row"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <Divider
                      style={{
                        height: '2px',
                        backgroundColor: environment.primaryColor,
                        width: '38%',
                      }}
                    />
                  </Grid>
                  <Typography
                    variant="h2"
                    style={{
                      color: environment.mainColor,

                      fontFamily: `Cormorant Infant`,
                    }}
                    sx={{
                      fontSize: { xs: '40px', md: '60px', lg: '40px' },
                      marginBottom: '10px',
                    }}
                  >
                    {t('aboutTitleSchool')}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: '#555555',
                      fontFamily: `Cormorant Infant`,
                    }}
                  >
                    {t('aboutDetailsSchool')}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: '#292E36',
                      fontFamily: `sans-serif`,
                      marginTop: '30px',
                    }}
                  >
                    {'JOSEFINE'}
                  </Typography>
                  <img
                    loading="lazy"
                    src={sigschool}
                    alt="loading..."
                    style={{ width: '165px', marginTop: '10px' }}
                  />
                  <Button
                    variant="outlined"
                    style={{
                      border: `1px solid ${environment.mainColor}`,
                      color: '#A01913',
                      marginTop: '50px',
                      width: '20%',
                      borderRadius: '0px',
                      fontSize: '12px',
                      fontFamily: 'system-ui',
                    }}
                  >
                    See More
                  </Button>
                </Grid>
              )}

              <Grid item lg={5} sm={12}>
                <img
                  loading="lazy"
                  src={
                    environment.restaurantId === 'istanbulDk'
                      ? homet
                      : environment.restaurantId === 'alyousef'
                      ? aboutAlyousef
                      : environment.restaurantId === 'filfil'
                      ? aboutFilfil
                      : environment.restaurantId === 'taqwasbakery'
                      ? homet
                      : environment.restaurantId === 'ttDemo'
                      ? hometabletuck
                      : aboutSchool
                  }
                  alt="loading..."
                  style={{ width: '100%', height: '100%' }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
}
export default AboutSection;
