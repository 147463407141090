import React, { useEffect, useState } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Box,
  Button,
} from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
// import { validateUserAddress } from './utils';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import {
  setDeliveryStore,
  setUser,
  userAddresses,
} from '../../../../../../libs/redux-slices/src';
import { useDispatch } from 'react-redux';
import Snipper from 'libs/components/src/lib/snipper/Snipper';
import AddressHandlerDialog from '../Address/AddressHandler';
import { textAlign } from '@mui/system';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { auth, firestore } from '@tabletuck/firebase-services';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeAPICall } from './utils';

function DeliveryDialog({
  token,
  setErr,
  open,
  handleClose,
  items,
  handleDefault,
  handleCloseCartDialoge,

  setAddressStore,
  handleFee,
}) {
  const [t, i18n] = useTranslation();

  const [loading, setLoading] = useState(null);
  const dispatch = useDispatch();
  const [openAdd, setOpenAdd] = React.useState(false);
  const adresses = useSelector((state) => state.authReducer.userAddresses);
  const user = useSelector((state) => state.authReducer.userData);
  const validateUserAddress = async (token, address, addressId) => {
    const { city, displayName, state, route, streetNumber, lat, lng, zipCode } =
      address;
    try {
      const tokenId = await auth?.currentUser
        .getIdToken()
        .then((token) => token);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: tokenId,
      };

      const { data } = await axios.post(
        `${environment?.apiUrl}/getStores?restaurantId=${environment.restaurantId}`,
        {
          uid: token,
          city,
          state,
          displayName,
          route,
          streetNumber,
          lat,
          lng,
          zipCode,
        },
        { headers }
      );
      if (!data.delivery.length) {
        throw new Error('This address is out of service.');
      } else {
        await firestore.doc(`users/${token}`).set(
          {
            defaultDeliveryAddress: addressId,
          },
          {
            merge: true,
          }
        );
        await firestore
          .doc(`users/${token}`)
          .collection('addresses')
          .doc(addressId)
          .set(
            {
              delivery_store_id: data.delivery[0].id,
            },
            {
              merge: true,
            }
          );

        await firestore
          .collection('users')
          .doc(user?.uid)
          .collection('addresses')
          .get()
          .then((docs) => {
            let adds = [];
            docs.forEach((doc) => {
              adds.push(doc.data());
            });
            dispatch(userAddresses(adds));
          });
        const updatedUser = { ...user, defaultDeliveryAddress: addressId };
        dispatch(setUser({ userData: updatedUser }));

        const doc = await firestore
          .collection('users')
          .doc(token)
          .collection('addresses')
          .doc(addressId)
          .get();
        if (doc?.exists) {
          return {
            displayName: doc.data().displayName,
            storeId: data.delivery[0].id,
          };
        }
      }
    } catch (e) {
      console.log('erore', e.message);
      //call error (e)
      return e.message;
    }
  };
  const handleClickOpen = () => {
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };
  return items === null || items === undefined || items?.length === 0 ? (
    <AddressHandlerDialog
      open={open}
      handleClose={() => {
        handleClose();
      }}
    />
  ) : (
    <Dialog onClose={handleClose} open={open} style={{ borderRadius: '250px' }}>
      <div
        style={{
          padding: '15px 20px 0px 0px',
          display: 'flex',
          justifyContent: 'end',
          borderRadius: '25px',
        }}
      >
        {' '}
        <CancelRoundedIcon
          style={{
            color: 'black',
            cursor: 'pointer',
            marginLeft: '15px',
          }}
          onClick={handleClose}
        />
      </div>

      <DialogTitle
        style={{
          display: 'grid',
          justifyContent: 'center',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography component="p" variant="h4" style={{ fontWeight: 'bold' }}>
            {' '}
            {t('menu.selectDeliveryAddress')}
          </Typography>
        </div>

        <div></div>
      </DialogTitle>

      <DialogContent dividers>
        {items?.map((item, index) => (
          <>
            <Box
              key={item.id}
              onClick={async () => {
                setLoading(index);
                const res = await validateUserAddress(token, item, item.id);
                const fee = await makeAPICall(
                  item?.delivery_store_id,
                  item?.id,
                  user?.uid
                );
                handleFee(fee);

                const { displayName, storeId } = res;
                setLoading(null);
                if (!res.displayName) {
                  handleDefault(res);
                  setErr('');
                } else {
                  handleDefault(res.displayName);
                  dispatch(
                    setDeliveryStore({ address: item.id, store: storeId })
                  );
                  setAddressStore(storeId);
                }
                handleClose();
              }}
              style={{ marginTop: '20px' }}
            >
              {loading === index ? (
                <div
                  style={{
                    marginLeft: 5,
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    width: '100%',
                    fontSize: 20,
                    fontWeight: '500',
                    // textAlign:'center'
                  }}
                >
                  {t('menu.validateAddress')}
                  <Snipper color="red" />
                </div>
              ) : (
                <div className="p-2 flex flex-col justify-start cursor-pointer">
                  <h5
                    class="text-gray-900 text-xl font-medium"
                    style={{ marginLeft: '10px' }}
                  >
                    {item?.nickName}
                    <br />{' '}
                    <p style={{ fontSize: '14px' }}>{item?.displayName}</p>
                  </h5>
                </div>
              )}
              <Divider />
            </Box>
          </>
        ))}
        <div style={{ height: '30px' }} />
        <Button
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
          variant="text"
          style={{
            width: '100%',
            boxShadow:
              '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            color: environment.mainColor,
            padding: '10px',
            borderRadius: '20px',
            fontWeight: 'bold',
          }}
          onClick={handleClickOpen}
        >
          {' '}
          <AddLocationAltIcon /> {t('menu.addNewAddress')}
        </Button>
        <AddressHandlerDialog open={openAdd} handleClose={handleCloseAdd} />
      </DialogContent>
    </Dialog>
  );
}

export default DeliveryDialog;
