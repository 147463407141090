import { Container, Box, Divider } from '@mui/material';

import Typography from '@mui/material/Typography';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth, firestore } from '@tabletuck/firebase-services';
import Snipper from 'libs/components/src/lib/snipper/Snipper';
import {
  CheckItem,
  CurrentItemValue,
  setCart,
  setUser,
  updateCode,
} from '@tabletuck/redux-slices';
import { checkCoupon } from '../CartDrawer/utils';
import { updateUser } from '../../../../../../libs/redux-slices/src';

const CartDrawerCard = ({
  item,
  code,
  couponRes,
  setDiscount,
  setIsVerified,
  total,
  setTotal,
  setRest,
  typeState,
  itemErr,
  outofStock,
  setOutofStockcheck,
  setOutofStockcheckDelivery,
  outofStockDeliveryStore,
  ApplyCoupon,
  setCoupon,
  setCouponRes,
}) => {
  const price = useRef();
  const items = useSelector((state) => state.shopReducer.currentItem);

  useEffect(() => {
    price.current = +item.price;

    if (item?.customization) {
      for (let modifier in item.customization) {
        for (let option of item.customization[modifier]) {
          price.current += +option.price * +option.quantity;
        }
      }
    }
  }, [item, outofStock]);

  const token = useSelector((state) => state.authReducer.token);
  const cart = useSelector((state) => state.shopReducer.cart);

  const deliveryStore = useSelector((state) => state.shopReducer.deliveryStore);
  const [calculatedPrice, setcalulatedPrice] = useState(
    item?.calculatedPrice || 0
  );
  const [loading, setLoading] = useState(false);
  const [changeload, setChangeload] = useState(false);
  const [value, setValue] = useState(item.quantity);
  const [changeType, setChType] = useState('');
  const dispatch = useDispatch();
  const [custom, setCustom] = useState('');
  const [errDelivery, setErrDelivery] = useState('');
  const [checkStock, setCheckStock] = useState('');
  const [checkStockDelivery, setCheckStockDelivery] = useState('');
  const [outofStockTimeDelivery, setOutofStockTimeDelivery] = useState(null);
  const [outofStockTime, setOutofStockTime] = useState(null);
  const [outofStockDelivery, setOutOfStockDelivery] = useState(null);
  let date = new Date();
  const user = useSelector((state) => state.authReducer.userData);

  useEffect(() => {
    setOutOfStockDelivery(outofStockDeliveryStore);
  }, [outofStockDeliveryStore]);
  useEffect(() => {
    setcalulatedPrice(item?.calculatedPrice);
    setValue(item?.quantity);
  }, [item]);

  const handleChange = async (type, data) => {
    setChType(type);
    try {
      //get item and change it's quantity
      const bagItems = [...user?.bagItems];
      const test = (item) =>
        item.itemId == data.itemId &&
        item.quantity === data.quantity &&
        item.calculatedPrice === data.calculatedPrice;
      const index = bagItems.findIndex(test);
      bagItems[index] = {
        ...bagItems[index],
        quantity: type == 'inc' ? value + 1 : value - 1,
        calculatedPrice:
          type == 'inc'
            ? calculatedPrice + price.current
            : calculatedPrice - price.current,
      };
      dispatch(setCart(bagItems));
      const updatedUser = { ...user, bagItems: bagItems };
      dispatch(setUser({ userData: updatedUser }));
      await firestore.collection('users').doc(auth?.currentUser?.uid).set(
        {
          bagItems,
        },
        { merge: true }
      );
      code?.length && ApplyCoupon();
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleRemove = async (card) => {
    setOutofStockcheck('');

    dispatch(
      updateCode({
        code: '',
        discount: 0,
        isVerify: false,
      })
    );
    setCoupon('');
    setCouponRes('');
    try {
      setLoading(true);
      const bags = [...user?.bagItems];
      console.log('item to delete in handle remove', item);
      const isFound = (item) =>
        item.itemId === card.itemId &&
        item.calculatedPrice === card.calculatedPrice &&
        item.quantity === card.quantity;

      const res = bags.findIndex(isFound);
      if (res !== -1) {
        bags?.splice(res, 1);
      }

      await firestore
        .collection('users')
        .doc(auth?.currentUser?.uid)
        .update({
          bagItems: bags,
        })
        .then(() => {
          dispatch(setCart(bags));
          const updatedUser = { ...user, bagItems: bags };
          dispatch(setUser({ userData: updatedUser }));
          setLoading(false);
          checkItem();
        });
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    Object?.keys(item?.customization)?.map((key, index) => {
      item.customization[key].map((cust) => {
        setCustom((prev) => prev + cust.modifierTitle + ', ');
      });
    });
    return () => {
      setCustom('');
    };
  }, [item]);
  //pickup_enabled
  //dinein_enabled
  //delivery_enabled
  // const checkItem = () => {
  //   setErrDelivery('');
  //   dispatch(CheckItem(''));
  //   if (item && !item.delivery_enabled && typeState === 'delivery') {
  //     setErrDelivery('This item is not available for delivery');
  //     dispatch(CheckItem('This item is not available for delivery'));
  //   } else if (item && !item.pickup_enabled && typeState === 'pickup') {
  //     setErrDelivery('This item is not available for pickup');
  //     dispatch(CheckItem('This item is not available for pickup'));
  //   } else if (item && !item.dinein_enabled && typeState === 'dinein') {
  //     setErrDelivery('This item is not available for dine-in');
  //     dispatch(CheckItem('This item is not available for dine-in'));
  //   } else {
  //     dispatch(CheckItem(''));
  //     setErrDelivery('');
  //   }
  //   //
  // };
  const checkItem = () => {
    setErrDelivery(''); // Clear any existing error message
    dispatch(CheckItem('')); // Clear the error using the Redux action

    if (item) {
      const foundItem = items.find((i) => i.id === item.itemId);

      if (!foundItem) {
        setErrDelivery('This item is not available');
        dispatch(CheckItem('This item is not available'));
      } else {
        if (!foundItem?.delivery_enabled && typeState === 'delivery') {
          setErrDelivery('This item is not available for delivery');
          dispatch(CheckItem('This item is not available for delivery'));
        } else if (!foundItem?.pickup_enabled && typeState === 'pickup') {
          setErrDelivery('This item is not available for pickup');
          dispatch(CheckItem('This item is not available for pickup'));
        } else if (!foundItem?.dinein_enabled && typeState === 'dinein') {
          setErrDelivery('This item is not available for dine-in');
          dispatch(CheckItem('This item is not available for dine-in'));
        } else {
          dispatch(CheckItem(''));
          setErrDelivery('');
        }
      }
    }
  };

  useEffect(() => {
    checkItem();
  }, [typeState]);
  const checkoutOfstockDineInorPick = () => {
    setOutofStockcheck(false);
    if (outofStock && outofStock?.length === 0) {
      setCheckStock('');
    } else
      outofStock &&
        outofStock.filter((c) => {
          if (c?.id === item?.itemId && typeState != 'delivery') {
            setCheckStock('This item is out of stock');
            setOutofStockcheck(true);
            setOutofStockTime(
              new Date(c?.out_of_stock_end_date?.seconds * 1000)
            );
          } else {
            setCheckStock('');
            setOutofStockcheck(false);
          }
        });
  };

  const checkoutOfstockDelivery = () => {
    if (outofStockDelivery && outofStockDelivery?.length === 0) {
      setCheckStockDelivery('');
    } else
      outofStockDelivery &&
        outofStockDelivery.filter((c) => {
          if (c?.id === item?.itemId && typeState === 'delivery') {
            setCheckStockDelivery('This item is out of stock');
            setOutofStockcheckDelivery(true);
            setOutofStockTimeDelivery(
              new Date(c?.out_of_stock_end_date?.seconds * 1000)
            );
          } else setCheckStockDelivery('');
        });
  };
  useEffect(() => {
    if (checkStock != '') {
      setOutofStockcheck(true);
    } else setOutofStockcheck(false);
    if (checkStockDelivery != '') {
      setOutofStockcheckDelivery(true);
    } else setOutofStockcheckDelivery(false);
    checkoutOfstockDineInorPick();
    checkoutOfstockDelivery();
  }, [
    itemErr,
    typeState,
    errDelivery,
    outofStock,
    checkStockDelivery,
    setOutofStockTime,
    setCheckStockDelivery,
    setOutofStockcheck,
  ]);
  return (
    item && (
      <Container maxWidth="lg">
        <Box style={{ marbinBottom: '15px', marginTop: '20px' }}>
          <p style={{ color: 'red' }}>{errDelivery}</p>
          <p style={{ color: 'red' }}>
            {moment(outofStockTime).isAfter(date) && typeState != 'delivery'
              ? checkStock
              : ''}
            {moment(outofStockTimeDelivery).isAfter(date) &&
            typeState === 'delivery'
              ? checkStockDelivery
              : ''}
          </p>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div
                style={{
                  width: '200px',
                  height: '100px',
                  position: 'relative',
                  overflow: 'hidden',
                  paddingRight: '12px',
                }}
              >
                <img
                  src={item?.image}
                  alt="card"
                  style={{
                    width: '100%', // Ensures the image fills the container horizontally
                    height: '100%', // Ensures the image fills the container vertically
                    objectFit: 'cover', // Ensures the entire image is covered
                    borderRadius: '15px',
                  }}
                />
              </div>
              <div style={{ display: 'grid', width: '100%' }}>
                <Typography
                  component="a"
                  variant="h6"
                  sx={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 'bold',
                    display: 'flex',
                  }}
                >
                  {item && item?.title}
                </Typography>
                <Typography
                  component="a"
                  variant="h6"
                  sx={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    display: 'flex',
                    fontWeight: 'bold',
                  }}
                >
                  ${item && calculatedPrice?.toFixed(2)}
                </Typography>
                <Typography>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{ padding: '5px 10px 5px 0', cursor: 'pointer' }}
                      onClick={() => {
                        if (value > 1) {
                          handleChange('dec', item);
                          setValue((v) => v - 1);
                          let myvalue = value - 1;
                          dispatch(CurrentItemValue(myvalue));
                          setcalulatedPrice((old) => old - price.current);
                        }
                      }}
                    >
                      {changeload && changeType == 'dec' ? (
                        <Snipper color={'green'} />
                      ) : (
                        <RemoveCircleRoundedIcon
                          style={{ color: value > 1 ? 'green' : 'gray' }}
                        />
                      )}
                    </div>
                    <Typography
                      component="a"
                      variant="h6"
                      sx={{
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        display: 'flex',
                        fontWeight: 'bold',
                        marginTop: '3px',
                      }}
                    >
                      {value}
                    </Typography>
                    <div
                      style={{ padding: '5px 10px 5px', cursor: 'pointer' }}
                      onClick={() => {
                        if (value < 10) {
                          handleChange('inc', item);
                          setValue((v) => v + 1);
                          let myvalue = value + 1;
                          dispatch(CurrentItemValue(myvalue));
                          setcalulatedPrice((old) => old + price.current);
                        }
                      }}
                    >
                      {changeload && changeType == 'inc' ? (
                        <Snipper color={'green'} />
                      ) : (
                        <AddCircleOutlinedIcon
                          style={{ color: value < 10 ? 'green' : 'gray' }}
                        />
                      )}
                    </div>
                  </div>
                </Typography>
                {/* <Typography
                  component="a"
                  variant="body2"
                  sx={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    display: 'flex',
                    fontWeight: 'bold',
                    color: 'gray',
                  }}
                >
                  Customize Special Instructions:
                </Typography>
                <Typography
                  component="a"
                  variant="body2"
                  sx={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    display: 'flex',
                    fontWeight: 'bold',
                    color: 'gray',
                  }}
                >
                  {custom}
                </Typography> */}
              </div>
            </div>
            {loading ? (
              <Snipper color={environment.mainColor} />
            ) : (
              <DeleteIcon
                onClick={() => handleRemove(item)}
                style={{
                  color: environment.mainColor,
                  display: 'flex',
                  cursor: 'pointer',
                }}
              />
            )}{' '}
          </div>
        </Box>
        <Divider style={{ marginTop: '5px' }} />
      </Container>
    )
  );
};
export default CartDrawerCard;
