import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Slide from '@mui/material/Slide';
import OrderSummary from './OrderSummary';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { auth, firestore } from '../../../../../../libs/firebase-service/src';
import Rating from '@mui/material/Rating';
import Loading from 'react-fullscreen-loading';

import {
  clearCartData,
  setCart,
  setUser,
  UdpateNote,
} from '../../../../../../libs/redux-slices/src';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchData, handleToaster } from '../../Utils/UtilsFunctions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderHistory = (props) => {
  const [open, setOpen] = useState(false);
  const [openReorder, setOpenReorder] = useState(false);
  const [openRate, setOpenRate] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(false);
  const [value, setValue] = useState(5);
  const [feedback, setFeedBack] = useState('');
  const [updatedData, setUpdatedData] = useState({});
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(false);
  const cart = useSelector((state) => state.shopReducer.cart);
  const user = useSelector((state) => state.authReducer.userData);

  const handleCloseReorder = () => {
    setOpenReorder(false);
  };
  const handleCloseRate = () => {
    setOpenRate(false);
    setFeedBack('');
  };
  const handleCloseCancel = () => {
    setCancelOrder(false);
  };
  const dispatch = useDispatch();
  const handleGetNewestOrder = async () => {
    try {
      const querySnapshot = await firestore
        .collection('orders')
        .orderBy('created_at', 'desc') // Order by 'created_at' in descending order
        .limit(1) // Limit the result to 1 document
        .get();

      if (!querySnapshot.empty) {
        const newestOrder = querySnapshot.docs[0];
        setUpdatedData(newestOrder.data());
      }
    } catch (error) {
      console.error('Error fetching newest order:', error);
    }
  };

  useEffect(() => {
    handleGetNewestOrder();
  }, []);
  const {
    uid,
    order_id,
    deliveryOption,
    store,
    order_total,
    order_items,
    created_at,
    order_status,
    cancellation_time,
    rated,
  } = props.order;
  const token = useSelector((state) => state.authReducer.token);
  const [rate, setRate] = useState(rated);

  const Reorder = async () => {
    try {
      const updatedCart = [...cart, ...order_items];
      const updatedUser = { ...user, bagItems: updatedCart };
      await firestore.collection('users').doc(auth?.currentUser?.uid).set(
        {
          bagItems: updatedCart,
        },
        { merge: true }
      );
      dispatch(setCart(updatedCart));
      dispatch(setUser({ userData: updatedUser }));
      setOpenReorder(false);
    } catch (error) {
      console.log('Error in Reorder function:', error);
    }
  };

  const CancelOrder = async () => {
    setLoading(true);
    setErr('');
    try {
      setLoading(true);

      auth?.currentUser.getIdToken().then(async (token) => {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: token,
        };
        setLoading(true);

        await fetchData(
          `${environment?.apiUrl}/cancelOrderV2?restaurantId=${environment.restaurantId}`,
          'post',
          {
            orderId: order_id,
            reason: 'cancel order',
            typeCancel: 'Customer',
            uid: auth?.currentUser?.uid,
          },
          headers
        )
          .then((c) => {
            handleToaster('success', c.data.msg);

            setLoading(false);
            props.setCancelOrderget(true);
          })
          .catch((c) => {
            handleToaster('error', c.response.data.message);
            console.log('errror', c.response.data.message);

            console.log('errror message', c.response.data.message);

            setLoading(false);
          });
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setErr(err);
    }

    setCancelOrder(false);
    setLoading(false);
  };
  const RateOrder = async () => {
    try {
      setLoading(true);
      setErr('');

      const token = await auth?.currentUser.getIdToken();
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };

      await fetchData(
        `${environment?.apiUrl}/review?restaurantId=${environment.restaurantId}`,
        'post',
        {
          orderId: order_id,
          text: feedback,
          rating: value,
        },
        headers
      );
      setLoading(false);
      setFeedBack('');
      handleToaster('success', 'sent successfully');

      handleCloseRate();
      setRate(true);
    } catch (err) {
      handleToaster('error', err.response.data.message);
      setLoading(false);
      handleCloseRate();

      // Handling errors
      console.log('Error:', err);
    }
  };

  const [created, setCreated] = useState(new Date(created_at?.seconds * 1000));
  const [cancel, setCancel] = useState(
    new Date(cancellation_time?.seconds * 1000)
  );

  let storeName = store.restaurant_name;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let d = new Date();
  const [t, i18n] = useTranslation();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Card
        sx={{
          display: 'flex',
          marginTop: '15px',
          marginBottom: '15px',
          cursor: 'pointer',
        }}
      >
        <Grid container paddingBottom={2}>
          <Grid item xs={8}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography
                  component="div"
                  variant="h6"
                  style={{
                    fontWeight: 'bold',
                    color: environment.mainColor,
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {t('profilee.order')} #{order_id}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                  {deliveryOption === 'delivery' ||
                  deliveryOption === 'noDriverDelivery'
                    ? `${t('profilee.deliveredFrom')}`
                    : deliveryOption === 'pickup'
                    ? `${t('profilee.pickedUp')}`
                    : `${t('profilee.dineInFrom')}`}{' '}
                  <span style={{ color: 'black' }}>{storeName} </span>
                </Typography>
                <Typography
                  component="div"
                  variant="body2"
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  ${order_total}
                </Typography>

                <Divider />
                {order_items.map((items) => (
                  <Typography
                    variant="subtitle1"
                    component="div"
                    color="text.secondary"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    {items?.name === undefined ? items.title : items.name} x{' '}
                    {items?.quantity}
                  </Typography>
                ))}
                <Typography
                  variant="subtitle1"
                  component="div"
                  color="text.secondary"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                  {moment(created).format('LLL')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  color="black"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  onClick={handleClickOpen}
                >
                  {t('profilee.ViewMore')}
                </Typography>

                {order_status === 'new' &&
                  moment(d).format('LLL') < moment(cancel).format('LLL') && (
                    <Button
                      variant="contained"
                      style={{
                        background: environment.mainColor,
                        borderRadius: '20px',
                      }}
                      onClick={() => setCancelOrder(true)}
                    >
                      {t('profilee.cancelOrder')}
                    </Button>
                  )}
                <Dialog
                  style={{ zIndex: 10, borderRadius: '30px' }}
                  open={cancelOrder}
                  keepMounted
                  onClose={handleCloseCancel}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-slide-description"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Typography
                        variant="h6"
                        style={{ fontWeight: 'bold', color: 'black' }}
                      >
                        Warning
                      </Typography>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description">
                      {t('profilee.areYouSureYouWantToCancelThisOrder')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        background: 'gray',
                        borderRadius: '20px',
                      }}
                      onClick={handleCloseCancel}
                    >
                      {t('profilee.cancel')}
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        background: loading ? 'gray' : environment.mainColor,
                        borderRadius: '20px',
                      }}
                      onClick={() => CancelOrder()}
                      disabled={loading}
                    >
                      {loading ? '...Loading' : 'Continue'}
                    </Button>
                  </DialogActions>
                </Dialog>
                {order_status === 'scheduled' &&
                  moment(d).format('LLL') < moment(cancel).format('LLL') && (
                    <Button
                      variant="contained"
                      style={{
                        background: environment.mainColor,
                        borderRadius: '20px',
                      }}
                      onClick={() => setCancelOrder(true)}
                    >
                      {t('profilee.cancelOrder')}
                    </Button>
                  )}
                <p style={{ color: 'red' }}>{err}</p>

                {cancellation_time > new Date() ? (
                  <Grid item lg={4}>
                    <Typography
                      variant="body2"
                      component="div"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        color: environment.mainColor,
                      }}
                    >
                      {t('profilee.cancelOrder')}
                    </Typography>
                  </Grid>
                ) : (
                  ''
                )}
              </CardContent>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography
                  variant="body2"
                  style={{
                    //   marginTop: '5px',
                    //   marginBottom: '5px',

                    display: 'flex',
                    justifyContent: 'center',

                    // fontWeight: 'bold',
                    // color: environment.mainColor,
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {order_status === 'delivered' ? (
                    deliveryOption === 'pickup' ? (
                      <span>
                        {t('profilee.picked_Up')}{' '}
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          style={{ color: 'green' }}
                        />
                      </span>
                    ) : deliveryOption === 'dinein' ? (
                      <span>
                        {t('profilee.dined_In')}{' '}
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          style={{ color: 'green' }}
                        />
                      </span>
                    ) : (
                      <span>
                        {t('profilee.delivered')}{' '}
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          style={{ color: 'green' }}
                        />
                      </span>
                    )
                  ) : order_status === 'cancelled' ? (
                    <span>
                      {' '}
                      <FiberManualRecordIcon
                        fontSize="small"
                        style={{ color: 'red', height: '15px' }}
                      />
                      {t('profilee.cancelled')}
                    </span>
                  ) : order_status === 'scheduled' ? (
                    <span>
                      {t('profilee.scheduled')}
                      <CheckCircleOutlineIcon
                        fontSize="small"
                        style={{ color: 'green' }}
                      />
                    </span>
                  ) : order_status === 'ready_to_pickup' ? (
                    <span>{t('profilee.Readyforpickup')}</span>
                  ) : order_status === 'pickedup' ? (
                    <span>{t('profilee.Driverpickedup')}</span>
                  ) : order_status === 'new' ? (
                    <span>
                      <FiberManualRecordIcon
                        fontSize="small"
                        style={{ color: 'blue' }}
                      />
                      {t('profilee.active')}
                    </span>
                  ) : (
                    'Active'
                  )}
                </Typography>
              </CardContent>
            </Box>
          </Grid>
          {order_status === 'new' || order_status === 'scheduled' ? (
            ''
          ) : (
            <>
              <Grid container style={{ marginTop: '5px', marginLeft: '10px' }}>
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    style={{
                      background: loading ? 'gray' : environment.mainColor,
                      borderRadius: '20px',
                    }}
                    onClick={() => {
                      setOpenReorder(true);
                    }}
                  >
                    <ShoppingBasketIcon
                      style={{ marginLeft: '2px', marginRight: '12px' }}
                    />{' '}
                    {t('profilee.reorder')}
                  </Button>

                  <Dialog
                    style={{ zIndex: 10, borderRadius: '30px' }}
                    open={openReorder}
                    keepMounted
                    onClose={handleCloseReorder}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogContent>
                      <DialogContentText
                        id="alert-dialog-slide-description"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 'bold', color: 'black' }}
                        >
                          {t('profilee.confirmreorder')}
                        </Typography>
                      </DialogContentText>
                      <DialogContentText id="alert-dialog-slide-description">
                        {t(
                          'profilee.previousorderitemswillbeaddedtoyourcurrentcart'
                        )}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          background: 'gray',
                          borderRadius: '20px',
                        }}
                        onClick={handleCloseReorder}
                      >
                        {t('profilee.cancel')}
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          background: environment.mainColor,
                          borderRadius: '20px',
                        }}
                        onClick={() => Reorder()}
                      >
                        {t('basket.addItems')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={5}>
                  {!rate && order_status === 'delivered' && (
                    <>
                      <Button
                        variant="contained"
                        style={{
                          background: environment.mainColor,
                          borderRadius: '20px',
                          marginLeft: '3px',
                        }}
                        onClick={() => setOpenRate(true)}
                      >
                        {t('profilee.rateOrder')}
                      </Button>

                      <Dialog
                        style={{ zIndex: 10, borderRadius: '30px' }}
                        open={openRate}
                        keepMounted
                        onClose={handleCloseRate}
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogContent style={{ width: '300px' }}>
                          <DialogContentText
                            id="alert-dialog-slide-description"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('profilee.rateOrder')}
                            </Typography>
                          </DialogContentText>
                          <DialogContentText
                            id="alert-dialog-slide-description"
                            style={{
                              display: 'block',
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <div style={{ marginTop: '20px' }}>
                              <Rating
                                name="simple-controlled"
                                value={value}
                                onChange={(event, newValue) => {
                                  setValue(newValue);
                                }}
                              />
                            </div>
                            <br />
                            <TextField
                              id="outlined-multiline-static"
                              label="Feedback"
                              multiline
                              style={{ width: '250px' }}
                              rows={4}
                              onChange={(e) => setFeedBack(e.target.value)}
                              value={feedback}
                            />
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              background: 'gray',
                              borderRadius: '20px',
                            }}
                            onClick={handleCloseRate}
                          >
                            {t('profilee.cancel')}
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              background: loading
                                ? 'gray'
                                : environment.mainColor,
                              borderRadius: '20px',
                            }}
                            onClick={() => RateOrder()}
                            disabled={loading}
                          >
                            {loading ? '...Loading' : 'Continue'}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <div>
          <OrderSummary
            order_id={order_id}
            storeName={storeName}
            order={props.order}
            open={open}
            handleClose={handleClose}
            Transition={Transition}
          />
          <Loading
            loading={!!loading}
            background="rgba(86, 100, 210, 0.1)"
            loaderColor={environment.mainColor}
          />
        </div>
      </Card>
    </>
  );
};

export default OrderHistory;
