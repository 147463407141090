import { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Grid,
  useTheme,
  Button,
  Container,
  Hidden,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
} from '@mui/material';
import { Field, Formik } from 'formik';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loading from 'react-fullscreen-loading';
import { FiArrowLeftCircle } from 'react-icons/fi';
import InputAdornment from '@mui/material/InputAdornment';
import ReactCodeInput from 'react-code-input';
import signupSchool from '../../../assets/signupSchool.png';
import signupAlyosef from '../../../assets/alyousef/login.png';

import {
  firestore,
  auth,
  recaptchaVerifier,
  _customApp,
} from '@tabletuck/firebase-services';
import { Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import signup from '../../../assets/signup.gif';
import tabletuck from '../../../assets/tabletuck.gif';

import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { loggedInSuccess } from '@tabletuck/redux-slices';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import makeStyles from '@mui/styles/makeStyles';
import CustomPhoneNumber from '../../components/PhoneNumber/PhoneInput';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import './styles.css';
import { ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { setCart } from '../../../../../../libs/redux-slices/src';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});
const SignUp = () => {
  const token = useSelector((state) => state.authReducer.token);
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [expandForm, setExpandForm] = useState(false);
  const [OTP, setOTP] = useState('');
  const [verifiedNum, setVerifiedNum] = useState(false);
  const [user, setUser] = useState();
  const [open, setOpen] = useState(false);
  const [err, setErr] = useState('');
  const [isFirst, setIsFirst] = useState(false);
  const userData = useSelector((state) => state.authReducer.userData);
  const [t, il8n] = useTranslation();

  const openDialog = (user) => {
    if (user.fullName.length === 0 && user.email.length === 0) {
      setAuthenticated(false);
      // Save the current URL before login
      setOpen(true);
    } else if (user.fullName.length > 0 && user.email.length > 0) {
      setAuthenticated(true);
      setOpen(false);
      navigate('/');
      dispatch(loggedInSuccess({ token: user.uid }));
    } else {
      setOpen(true);

      // open dialog with add email and username
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = {
      email: values.email,
      fullName: values.fullName,
      sendSms: values.sendSms,
    };
    try {
    
      if (!authenticated) {
        await firestore
          .collection('users')
          .doc(id)
          
           .set(formData, { merge: true })
          .catch((e) => {
            console.log(e);
          });
        setLoading(false);
        setAuthenticated(true);
        setOpen(false);
        setErr('');
        dispatch(loggedInSuccess({ token: user.uid }));
        navigate('/');
      } else {
        setLoading(false);
        setOpen(false);
        setErr('');
      }
    } catch (e) {
      console.log(e.message);
      setErr(`${t('tryAgain')}`);
      setLoading(false);
    }
  };
  let phoneNumbert = phoneNumber.toString();
  phoneNumbert = phoneNumbert.replace(/\s/g, '');
  phoneNumbert = phoneNumbert.replace(/-/g, '');
  phoneNumbert = phoneNumbert.replace(/[{()}]/g, '');
  phoneNumbert = '+1' + phoneNumbert;
  const handleSubmitt = async (values) => {
    setLoading(true);
    try {
      if (!user) {
        const authUser = await auth.currentUser;
        const doc = await firestore.collection('users').doc(authUser?.uid);
        await firestore
          .collection('users')
          .doc(doc.id)
          .set({
            fullName: '',
            email: '',
            phoneNumber: phoneNumbert,
            uid: authUser.uid,
            bagItems: [],
            defaultPaymentAddress: '',
            defaultDeliveryAddress: '',
            defaultStore: '',
            pickupOrDelivery: '',
            creadtedAt: new Date(),
          })
          .then(async () => {
            await getUser(doc.id);
            setErr('');
          });
      } else {
        openDialog(user);
      }
    } catch (e) {
      console.log('erorr', e.message);
      setErr(e.message);
      setLoading(false);
    }
  };


  const getUser = async (userId) => {
    setLoading(true);
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }
  
      const userDoc = await firestore.collection('users').doc(userId).get();
  
      if (userDoc.exists) {
  
        setUser(userDoc.data());
        setId(userDoc.id);
        window.localStorage.setItem('docid', userDoc.id);
  
        openDialog(userDoc.data());
        setIsFirst(true);
      } else {
        console.log("Doc doesn't exist");
        handleSubmitt();
      }
    } catch (e) {
      console.log(e.message);
      setErr(e.message);
    }
  };
  


  const handlePinChange = (pinCode) => {
    setOTP(pinCode);
  
    if (pinCode.length === 6) {
      let confirmationResult = window.confirmationResult;

      confirmationResult
        .confirm(pinCode)
        .then(async (userCredential) => {
  
          const uid = userCredential.user.uid;
  
           await getUser(uid); 
  
          setErr('');
          setVerifiedNum(true);
        })
        .catch((e) => {
          console.log(e.message);
         
          let notCorrect =
            'The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code SMS and be sure to use the verification code provided by the user.';
          e.message === notCorrect
            ? setErr('Oops, incorrect code! Resend the code.')
            : setErr(e.message);
        });
    }
  
    setOTP('');
  };

  const requestOTP = async (e) => {
    setErr('');
     dispatch(setCart([]));
      if (phoneNumbert.length < 12) {
      setErr(`${t('validNumber')}`);
      e.preventDefault();
    } else {
      setErr('');
      e.preventDefault();
      // setExpandForm(true);
      geterate();
      let appvarifier = window.recaptchaVerifier;
      await auth
        .signInWithPhoneNumber(phoneNumbert, appvarifier)
        .then((confirmationResult, result) => {
          
          
          setExpandForm(true);
          window.confirmationResult = confirmationResult;
          window.recaptchaVerifier = null;
          setErr('');
        })
        .catch((error) => {
          console.log('rechpecha error', error.message);
          window.recaptchaVerifier.reset();
          setExpandForm(false);
          setErr(`${t('validNumber')}`);
        });
    }
  };





  const storedItems = JSON.parse(localStorage.getItem('cartItems'));
 
  const cart = useSelector((state) => state.shopReducer.cart);

 
  const verifyOTP = (e) => {
    // setOTP(otp);
    if (OTP.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(OTP)
        .then(async (result) => {
          await getUser();
          // User signed in successfully.
          setErr('');
          setVerifiedNum(true);
        })
        .catch((e) => {
          console.log(e.message);
          let notCorrect =
            'The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.';
          e.message === notCorrect
            ? setErr(
                'Oops incorrect code! refresh the page to resend the code.'
              )
            : setErr(e.message);
        });
    } else setErr('Verification code has 6 numbers.');
    setOTP('');
  };

  const geterate = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new recaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
        _customApp
      );
      // window.recaptchaVerifier.reset()

      // window.recaptchaVerifier.clear();
    } else window.recaptchaVerifier.render();
  };
  const validateSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    fullName: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, 'User name must be only characters.')
      .min(3, 'User name must be at least 3 characters.')
      .required('User name is a required field.'),
      sendSms: Yup.boolean().oneOf([true], 'You must agree to receive messages'),
  });




  return (
    <>
   
   <Helmet>
        <title>Taqwa's Bakery and Restaurant | Sign up</title>
      
      </Helmet>    <Container maxWidth={'xl'}>
        
      <Grid container style={{ marginTop: '80px' }}>
        <Grid lg={6} justifyContent={'center'} style={{ marginTop: '100px' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h2" style={{ fontWeight: 'bold' }}>
              {t('signUp')}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" style={{ color: 'gray' }}>
              {t('signupDetails')}
            </Typography>
          </div>
          {open && (
            <Formik
              initialValues={{
                firstName: '',
                email: '',
                sendSms: false,
              }}
              validationSchema={validateSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                touched,
                handleSubmit,
                values,
                handleChange,
                handleBlur,
              }) => (
                <Dialog
                  open={open}
                  // onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    {t('updateYourData')}{' '}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText style={{ marginBottom: '10px' }}>
                      {t('subscribeWebsite')}
                    </DialogContentText>
                    <Grid item xs={12}>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        style={{
                          paddingBottom: '10px',
                        }}
                      >
                        <Field
                          fullWidth
                          as={TextField}
                          variant="outlined"
                          name="fullName"
                          label="Full name *"
                          helperText={touched.fullName && errors.fullName}
                          error={touched.fullName && errors.fullName && true}
                          onChange={handleChange('fullName')}
                          sx={{
                            borderColor: 'gray',
                            '& label.Mui-focused': {
                              color: 'black',
                            },
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: 'gray',
                              },
                            },
                          }}
                        />
                      </Grid>

                      <Field
                        fullWidth
                        as={TextField}
                        type="email"
                        variant="outlined"
                        name="email"
                        label="Email *"
                        error={touched.email && errors.email && true}
                        helperText={touched.email && errors.email}
                        InputProps={{
                          endAdornment: (
                            <MailOutlineIcon
                              style={{
                                color: environment.mainColor,
                              }}
                            />
                          ),
                        }}
                        onChange={handleChange('email')}
                        sx={{
                          borderColor: 'gray',
                          '& label.Mui-focused': {
                            color: 'black',
                          },
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: 'gray',
                            },
                          },
                        }}
                      />
                         <FormControl
                        required
                        error={touched.sendSms && Boolean(errors.sendSms)}
                        component="fieldset"
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.sendSms}
                                onChange={handleChange('sendSms')}
                                color="primary"
                              />
                            }
                            label="You agree to receive sms messages from our website"
                          />
                        </FormGroup>
                        {touched.sendSms && errors.sendSms && (
                          <FormHelperText>{errors.sendSms}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      style={{
                        color: environment.mainColor,
                      }}
                    >
                      {t('signUp')}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Formik>
          )}
          <Formik
            initialValues={{
              phoneNumber: '',
            }}
          >
            {({
              errors,
              touched,

              values,
              handleChange,
              handleBlur,
            }) => (
              <div>
                {/* {expandForm&&
                <Typography variant={"caption"} bold component={"span"}>
                  <div onClick={() => {navigate("/signup")}} style={{ color: "grey", display: "flex", alignItems: "center", cursor: "pointer",marginTop:"20px" }} variant={"secondary"}>
                    <FiArrowLeftCircle size={12} style={{ marginRight: "8px", transform: "translateY(2px)" }} />Try to sign in/up again
                  </div>
                </Typography>} */}
                <Grid spacing={3} container>
                  <Grid
                    item
                    justify="space-around"
                    container
                    xs={12}
                    spacing={1}
                    style={{
                      flexDirection: xsDown ? 'row' : 'unset',
                      marginTop: '10px',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <form onSubmit={requestOTP}>
                        <Field
                          fullWidth
                          as={CustomPhoneNumber}
                          variant="filled"
                          name="phoneNumber"
                          // label="Please enter your number "
                          required
                          style={{
                            position: 'relative',
                            borderRadius: '50%',
                            direction: 'initial',
                          }}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                          value={phoneNumber}
                          InputProps={{
                            readOnly: expandForm ? true : false,
                          }}
                          disabled={expandForm ? true : false}
                          sx={{
                            borderColor: 'gray',
                            '& label.Mui-focused': {
                              color: 'black',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'gray',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'gray',
                              },
                            },
                          }}
                        />
                        <br />
                        {!expandForm && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              size="large"
                              type="submit"
                              variant="contained"
                              style={{
                                backgroundColor: environment.mainColor,
                                marginTop: '15px',
                              }}
                            >
                              {t('verifyNumber')}
                            </Button>
                          </div>
                        )}
                        <div style={{ height: '10px', width: '100px' }} />
                        {expandForm === true && verifiedNum === false ? (
                          <>
                     
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                direction: 'initial',
                              }}
                            >
                              <ReactCodeInput
                                type="text"
                                onChange={handlePinChange}
                                fields={6}
                                inputMode="numeric"
                                pattern="[0-9]*" 
                              />
                            </div>
                            <br />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {/* <Button
                                size="large"
                                onClick={verifyOTP}
                                variant="contained"
                                style={{
                                  backgroundColor: environment.mainColor,
                                  marginBottom: '10px'
                                }}
                              >
                                Login
                              </Button> */}
                            </div>
                          </>
                        ) : null}
                        <Grid item md={12} xs={12}></Grid>
                        <div id="recaptcha-container"></div>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant="body"
                    style={{
                      color: 'red',
                      fontSize: 15,
                    }}
                  >
                    {err}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                ></div>
              </div>
            )}
          </Formik>
        </Grid>
        <Grid lg={6} style={{ display: 'flex', justifyContent: 'end' }}>
          <Hidden smDown>
            <img
              style={{
                width: environment?.restaurantId === 'filfil' ? '100%' : '66%',
              }}
              src={
                environment?.restaurantId === 'elmbrookschools'
                  ? signupSchool
                  : environment?.restaurantId === 'alyousef'
                  ? signupAlyosef
                  : environment?.restaurantId === 'ttDemo'
                  ? tabletuck
                  : signup
              }
              alt="signup"
            />
          </Hidden>
        </Grid>
      </Grid>
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={environment.mainColor}
      />
    </Container>
    </>  );
};
export default SignUp;
