import {
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
  Divider,
} from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AskIcon from '@mui/icons-material/ArrowForward';
// import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  // Discount,
  setCart,
  DefaultStore,
  UpdateCartData,
  updateUser,
  setUser,
  DefaultStoreFee,
} from '@tabletuck/redux-slices';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { CustomButton } from '@tabletuck/components';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import basket from '../../../assets/empty_bag.png';
import CartDrawerCard from '../Card/CartDrawerCard';
import TextField from '@mui/material/TextField';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import { checkCoupon, setDefaultStore, makeAPICall } from './utils';
import DeliveryDialog from './DeliveryDialog';
import DailogForm from './DailogForm';
import { auth, firestore } from '@tabletuck/firebase-services';
import Snipper from './Snipper';
import BillDetails from './BillDetails';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import {
  CheckItem,
  DefaultAddress,
  setDeliveryStore,
  UdpateNote,
  updateCode,
} from '../../../../../../libs/redux-slices/src';
import { useTranslation } from 'react-i18next';
const CartDrawer = ({
  handleCloseCartDialoge,
  openCartDialoge,
  userData,
  defaultStoreAdress,
  setDefaultStoreAdress,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const note = useSelector((state) => state.shopReducer.note);
  const cart = useSelector((state) => state.shopReducer.cart);
  const token = useSelector((state) => state.authReducer.token);
  const stores = useSelector((state) => state.shopReducer.stores);
  const user = useSelector((state) => state.authReducer.userData);
  const items = useSelector((state) => state.shopReducer.currentItem);
  const { code } = useSelector((state) => state.shopReducer.cartData);
  const addresses = useSelector((state) => state.authReducer.userAddresses);
  const deliveryStore = useSelector((state) => state.shopReducer.deliveryStore);

  const [err, setErr] = useState('');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [store, setStore] = useState('');
  const [open, setOpen] = useState(false);
  const [tempNote, setNote] = useState('');
  const [coupon, setCoupon] = useState('');
  const [reset, setRest] = useState(false);
  const [storeId, setStoreId] = useState('');
  const [itemErr, setItemErr] = useState('');
  const [discount, setDiscount] = useState(0);
  const [editCpn, setEditCpn] = useState(false);
  const [cartData, setCartData] = useState(cart);
  const [couponRes, setCouponRes] = useState('');
  const [dataStore, setDataStore] = useState([]);
  const [storesFee, setStoresFee] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isVerify, setIsverify] = useState(false);
  const [defaultAdd, setDefaultAdd] = useState('');
  const [outofStock, setOutOfStock] = useState('');
  const [checkStock, setCheckStock] = useState('');
  const [typeAddress, setTypeAddress] = useState('');
  const [checkoutErr, setCheckoutErr] = useState('');
  const [storeaddress, setStoreaddress] = useState('');
  const [addressStore, setAddressStore] = useState([]);
  const [enableDineIn, setEnableDineIn] = useState(false);
  const [enablePickup, setEnablePickup] = useState(false);
  const [outofStockTime, setOutofStockTime] = useState(null);
  const [enableDelivery, setEnableDelivery] = useState(false);
  const [type, setType] = useState(userData?.pickupOrDelivery);
  const [outofStockcheck, setOutofStockcheck] = useState(false);
  const [checkStockDelivery, setCheckStockDelivery] = useState('');
  const [outofStockDelivery, setOutOfStockDelivery] = useState(null);
  const [checkoutErroutofstock, setCheckoutErroutofstock] = useState('');
  const [outofStockTimeDelivery, setOutofStockTimeDelivery] = useState(null);
  const [outofStockDeliveryStore, setOutOfStockDeliveryStore] = useState([]);
  const [outofStockcheckDelivery, setOutofStockcheckDelivery] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [apiError, setApiError] = useState('');

  const checkItem = useSelector((state) => state.shopReducer.checkItem);
  const types = ['delivery', 'dinein', 'pickup'];
  const typeToShow = ['Delivery', 'Dine-in', 'Pickup'];
  const typeToShowAR = ['توصيل', 'تناول في المطعم', 'استلام'];
  const deliveryAddress = useSelector(
    (state) => state.shopReducer.deliveryAddress
  );

  const ApplyCoupon = async () => {
    setCouponRes('');
    setRest(true);
    const { isVerify, discount } = await checkCoupon(
      token,
      coupon,
      setCouponRes,
      setDiscount,
      setIsverify
    );
    if (isVerify) {
      dispatch(updateCode({ code: coupon, discount, isVerify }));
      setCoupon('');
      setEditCpn(false);
    }
    setRest(false);
  };
  useEffect(() => {
    setCartData(cart);
  }, [cart]);

  useEffect(() => {
    setType(userData?.pickupOrDelivery);
  }, [userData]);

  const getStores = async () => {
    stores?.map((store) => {
      if (store?.id === addressStore) {
        setData(store);
      }
    });
  };

  useEffect(() => {
    if (stores && stores.length === 1) {
      setDefaultStore(
        token,
        stores[0].id,
        stores[0].restaurant_name,
        setStore,
        stores[0]?.pickup_enabled,
        setEnablePickup,
        stores[0].dinein_enable,
        setEnableDineIn,
        stores[0].address.displayName,
        setStoreaddress,
        stores[0].address.placeId
      );
    }
  }, [stores]);
  const getAdressStore = async () => {
    stores?.map((store) => {
      if (store?.id === deliveryStore) {
        setOutOfStockDeliveryStore(store?.out_of_stock);
      }
    });
  };

  const getStore = async () => {
    stores?.map((storee) => {
      if (storee?.restaurant_name === store) {
        setDataStore(storee);
      }
    });
  };
  useEffect(() => {
    getStore();
  }, [store, setStore]);

  useEffect(() => {
    getStores();
  }, [addressStore]);

  useEffect(() => {
    getAdressStore();
  }, [deliveryStore]);

  const onDiscount = (data) => setDiscount(data);
  const resetData = () => {
    setCouponRes('');
    setNote('');
    setCoupon('');
  };

  const validateCheckoutData = () => {
    let tt;
    let isDataValid = true;
    if (data.id === addressStore) {
      tt = data.delivery_enabled;
    }

    if (type === '') {
      setCheckoutErr(`${t('basket.plzChooseType')}`);
      isDataValid = false;
    }
    if (type === 'delivery' && tt === undefined) {
      setCheckoutErr(`${t('basket.plzAddAdress')}`);
      isDataValid = false;
    }
    if (type === 'delivery' && !tt) {
      setCheckoutErr(`${t('basket.deliveryNotAvailable')}`);
      isDataValid = false;
    }
    if (type === 'pickup' && !enablePickup) {
      setCheckoutErr(`${t('basket.pickupNotAvailable')}`);
      isDataValid = false;
    }
    if (type === 'dinein' && !enableDineIn) {
      setCheckoutErr(
        store?.length > 0
          ? `${t('basket.dineinNotAvailable')}`
          : `${t('basket.pressTochangeStore')}`
      );
      isDataValid = false;
    }

    cartData.forEach((item) => {
      const foundItem = items.find((i) => i.id === item.itemId);
      if (!foundItem) {
        setCheckoutErr('This item is not available');
        isDataValid = false;
      } else {
        if (type === 'pickup' && item?.pickup_enabled == false) {
          setCheckoutErr(`${t('basket.removeItemNotAvailable')}`);
          isDataValid = false;
        } else if (type === 'dinein' && item?.dinein_enabled == false) {
          {
            setCheckoutErr(`${t('basket.itemNotAvailableForDineIn')}`);
            isDataValid = false;
          }
        } else if (type === 'delivery' && item?.delivery_enabled == false) {
          setCheckoutErr(`${t('basket.itemNotAvailableForDelivery')}`);
          isDataValid = false;
        }
        // ============checkoutOfstockDineIn orPick=========================
        if (outofStock && outofStock?.length === 0) {
          setCheckoutErroutofstock('');
        } else
          outofStock &&
            outofStock.filter((c) => {
              if (c?.id === item?.itemId && type != 'delivery') {
                setCheckoutErroutofstock('This item is out of stock');
                setOutofStockTime(
                  new Date(c?.out_of_stock_end_date?.seconds * 1000)
                );
                isDataValid = false;
              } else {
                setCheckoutErroutofstock('');
              }
            });
        // };
        // ====checkoutOfstockDelivery=====
        if (outofStockDelivery && outofStockDelivery?.length === 0) {
          setCheckoutErroutofstock('');
        } else
          outofStockDelivery &&
            outofStockDelivery.filter((c) => {
              if (c?.id === item?.itemId && type === 'delivery') {
                setCheckoutErroutofstock('This item is out of stock');
                setOutofStockTimeDelivery(
                  new Date(c?.out_of_stock_end_date?.seconds * 1000)
                );
                isDataValid = false;
              } else {
                setCheckStockDelivery('');
                setCheckoutErroutofstock('');
              }
            });
      }
    });
    return isDataValid;
  };

  const handleCheckout = () => {
    dispatch(
      UpdateCartData({
        discount,
        code,
        isVerify,
        subTotal: total,
        typeAddress,
        orderType: type,
        storeId,
      })
    );

    if (type === 'delivery') {
      if (defaultAdd && defaultAdd !== 'This address is out of service.') {
        navigate('/');
        navigate('/confirmorder');
        handleCloseCartDialoge();
        dispatch(DefaultAddress(defaultAdd));
      } else setCheckoutErr('Please check your address info');
    } else if (type === 'dinein' || type === 'pickup') {
      navigate('/');
      navigate('/confirmorder');
      handleCloseCartDialoge();
      dispatch(DefaultStore(store));
      dispatch(DefaultStoreFee(storesFee));
    } else {
      dispatch(DefaultStore(store));
      dispatch(DefaultAddress(defaultAdd));
      dispatch(DefaultStoreFee(storesFee));
    }
  };

  const getUser = async (token) => {
    try {
      const user = userData;
      const bags = user?.bagItems;
      bags?.length && dispatch(setCart(bags));
      const storeId = user?.defaultStore;
      setStoreId(user?.defaultStore);

      stores?.map((store) => {
        if (store?.id === storeId) {
          setOutOfStock(store?.out_of_stock);
          setStore(store?.restaurant_name);
          setStoresFee(store);
          setEnablePickup(store?.pickup_enabled);
          setEnableDelivery(store?.delivery_enabled);
          setEnableDineIn(store?.dinein_enable);
          setStoreaddress(store?.address?.displayName);
        }
      });
    } catch (e) {
      console.log(e?.message);
    }
  };
  const getUserAddresses = async (token) => {
    const bags = user?.bagItems;
    setStoreId(user?.defaultStore);
    bags?.length && dispatch(setCart(bags));
    const defaultDeliveryAddress = user?.defaultDeliveryAddress;

    try {
      await Promise.all(
        addresses?.map(async (a) => {
          if (a?.id === defaultDeliveryAddress) {
            setAddressStore(a?.delivery_store_id);
            dispatch(
              setDeliveryStore({
                address: defaultDeliveryAddress,
                store: a?.delivery_store_id,
              })
            );
            // try {
            // Make the API call and handle errors
            const apiResponse = await makeAPICall(
              a?.delivery_store_id,
              a?.id,
              user?.uid
            );
            // Clear any previous error if the API call succeed
            console.log('apiResponse', apiResponse);
            setDeliveryFee(0);
            if (apiResponse?.message || apiResponse?.error) {
              // Handle the error
              console.error(
                'Error occurred during API call:',
                apiResponse.error
              );
              setApiError(apiResponse?.message || apiResponse?.error);
              setDefaultAdd('');
              setDeliveryFee(0);
              // Perform error handling or return the error to the appropriate context
            } else {
              // No error, proceed with the obtained fee
              const fee = apiResponse;
              console.log('fee', fee);
              setDeliveryFee(fee);
              setDefaultAdd(a?.displayName);
              setTypeAddress(a?.nickName);
              setApiError('');
            }
          }
        }) ?? []
      );
    } catch (error) {
      console.error('Error in getUserAddresses:', error);
      setApiError(error?.message);
      setDefaultAdd('');
    }
  };

  {console.log(deliveryFee , 'deckckdkd')}
  useEffect(() => {
    userData && getUser(auth?.currentUser?.uid);
    auth?.currentUser?.uid && getUserAddresses(auth?.currentUser?.uid);
  }, [userData, store]);

  useEffect(() => {
    let items = 0;
    let price = 0;
    let total = 0;
    cartData.forEach((item) => {
      items += item.qty;
      price += item.qty * item.price;
      total += item.calculatedPrice;
    });
    setTotalItems(items);
    setTotalPrice(price);
    setTotal(total);
  }, [
    cartData,
    totalPrice,
    totalItems,
    setTotalPrice,
    setTotalItems,
    setTotal,
    total,
  ]);

  const handleClickOpen = () => {
    setOpen(true);
    setCheckoutErr('');
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeType = async (value) => {
    dispatch(CheckItem(''));
    setCheckoutErroutofstock('');
    setCheckoutErr('');
    setType(value);
    await firestore
      .collection('users')
      .doc(auth.currentUser.uid)
      .set({ pickupOrDelivery: value }, { merge: true })
      .then(() => {
        const updatedUser = { ...userData, pickupOrDelivery: value };
        dispatch(setUser({ userData: updatedUser }));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const CheckoutUserSignedin = () => {
    setCheckoutErroutofstock('');
    checkout();
    setCouponRes('');
    setEditCpn(false);
  };
  return (
    <Drawer
      variant="persistent"
      anchor={
        i18n.language === 'ar' || i18n.language === 'he' ? 'left' : 'right'
      }
      open={openCartDialoge}
    >
      {type == 'delivery' ? (
        <DeliveryDialog
          open={open}
          handleDefault={setDefaultAdd}
          handleCloseCartDialoge={handleCloseCartDialoge}
          token={user?.uid}
          setErr={setErr}
          handleClose={handleClose}
          items={addresses}
          setAddressStore={setAddressStore}
          handleFee={setDeliveryFee}
        />
      ) : (
        <DailogForm
          open={open}
          handleClose={handleClose}
          onClickItem={setStore}
          type={type}
          enablePickup={setEnablePickup}
          enableDinin={setEnableDineIn}
          enableDelivery={setEnableDelivery}
          setErr={setErr}
          onClickAdress={setStoreaddress}
          setDefaultStoreAdress={setDefaultStoreAdress}
          defaultStoreAdress={defaultStoreAdress}
        />
      )}
      <Box
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
        sx={{ width: { sm: '100%', lg: '600px' }, padding: '10px' }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={4} style={{ display: 'contents' }}>
            <CancelRoundedIcon
              style={{
                color: environment.mainColor,
                cursor: 'pointer',
                marginLeft: '15px',
              }}
              onClick={handleCloseCartDialoge}
            />{' '}
          </Grid>
          <Grid item lg={4}>
            <CustomButton
              bg={environment.mainColor}
              onClick={() => {
                navigate('/menu');
                handleCloseCartDialoge();
              }}
              buttonText={`${t('basket.keepOrdering')}`}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            flexDirection: 'row',
            padding: '20px 12%',
          }}
        >
          {types?.map((value, index) => (
            <>
              {(store?.length > 0 && !enablePickup && value == 'pickup') ||
              (store?.length > 0 && !enableDelivery && value == 'delivery') ||
              (store?.length > 0 && !enableDineIn && value == 'dinein') ? (
                ''
              ) : (
                <Button
                  disableRipple={true}
                  style={{
                    width: '100%',
                    backgroundColor:
                      store?.length > 0 && !enablePickup && value == 'pickup'
                        ? 'gray'
                        : store.length > 0 && !enableDineIn && value == 'dinein'
                        ? 'gray'
                        : type === value
                        ? environment.mainColor
                        : type !== value
                        ? 'lightgray'
                        : environment.mainColor,
                    color:
                      store?.length > 0 && !enablePickup && value == 'pickup'
                        ? 'white'
                        : store.length > 0 && !enableDineIn && value == 'dinein'
                        ? 'white'
                        : type === value
                        ? 'white'
                        : environment.mainColor,
                    borderRadius: '20px',
                  }}
                  disabled={
                    (store?.length > 0 && !enablePickup && value == 'pickup') ||
                    (store?.length > 0 && !enableDineIn && value == 'dinein')
                  }
                  variant={type === value ? 'contained' : 'text'}
                  onClick={() => {
                    handleChangeType(value);
                  }}
                >
                  {' '}
                  {typeToShow[index]}
                </Button>
              )}
            </>
          ))}
        </Box>

        <Box
          sx={{
            width: '100%',
            padding: '20px 14%',
            display: 'inherit',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          style={{ direction: 'initial' }}
        >
          <Typography
            component="a"
            variant="body1"
            sx={{
              mr: 2,
              flexGrow: 1,
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              color: 'black',
              fontWeight: 700,
            }}
          >
            {type === 'delivery' ? (
              (apiError || defaultAdd) && (
                <>
                  {apiError ||
                  defaultAdd != `${t('basket.addressOutService')}` ? (
                    <LocalShippingIcon
                      style={{ color: environment.mainColor }}
                      fontSize="large"
                    />
                  ) : (
                    <ReportGmailerrorredIcon style={{ color: 'red' }} />
                  )}
                </>
              )
            ) : (
              <>
                <StorefrontIcon
                  fontSize="large"
                  style={{
                    color: 'white',
                    backgroundColor: environment.mainColor,
                    width: '30px',
                    height: '30px',
                    borderRadius: '5px',
                  }}
                />
              </>
            )}{' '}
            {type === 'delivery' && !apiError ? (
              defaultAdd || err
            ) : (
              <>
                {store}
                <br />{' '}
                <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                  {type != 'delivery' && storeaddress}{' '}
                </span>
              </>
            )}
            {type === 'delivery' && apiError ? (
              <>
                <ReportGmailerrorredIcon style={{ color: 'red' }} />
                {t('basket.addressOutService')}
              </>
            ) : (
              <>
                {/* {store}
                <br />{' '}
                <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                  {type != 'delivery' && storeaddress}{' '}
                </span> */}
              </>
            )}
          </Typography>

          <br />
          <Typography
            component="a"
            variant="body1"
            sx={{
              mr: 2,
              flexGrow: 1,
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              color: environment.mainColor,
              fontWeight: 700,
              cursor: 'pointer',
            }}
            onClick={() => {
              handleClickOpen(true);
              // navigate('/menu-items')
              setCheckoutErr('');
            }}
          >
            {type === 'delivery'
              ? defaultAdd
                ? `${t('basket.changeAddress')}`
                : `${t('basket.changeAddress')}`
              : `${t('basket.changeStore')}`}{' '}
            {<KeyboardDoubleArrowRightIcon />}
          </Typography>
        </Box>
        <Divider style={{ border: '3px solid #C4C4C4' }} />
        <Box
          sx={{
            width: '100%',
            padding: '12px 2%',
          }}
        >
          <Typography
            component="a"
            variant="h6"
            sx={{
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              color: environment.mainColor,
              fontWeight: 700,
              display: 'flex',
            }}
          >
            {t('basket.myOrder')}
          </Typography>
        </Box>

        <Box
          sx={{
            width: '100%',
            padding: '10px 10%',
            display: 'contents',
          }}
        >
          <div style={{ paddingTop: '50px', display: 'contents' }}>
            {cartData && cartData?.length && cartData?.length > 0 ? (
              cartData.map((item, indx) => (
                <CartDrawerCard
                  key={indx + item.itemId}
                  item={item}
                  outofStock={outofStock}
                  total={totalPrice}
                  setTotal={setTotalPrice}
                  code={coupon}
                  couponRes={setCouponRes}
                  setDiscount={onDiscount}
                  setIsVerified={setIsverify}
                  typeState={type}
                  setItemErr={setItemErr}
                  itemErr={itemErr}
                  setOutofStockcheck={setOutofStockcheck}
                  setOutofStockcheckDelivery={setOutofStockcheckDelivery}
                  user={userData}
                  setRest={setRest}
                  outofStockDeliveryStore={outofStockDeliveryStore}
                  ApplyCoupon={ApplyCoupon}
                  setCoupon={setCoupon}
                  setCouponRes={setCouponRes}
                />
              ))
            ) : (
              <Container maxWidth="lg">
                <Grid
                  container
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '30px',
                    textAlign: 'center',
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <img src={basket} alt="emptybag" />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '30px',
                  }}
                >
                  <div>
                    {' '}
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      {t('basket.emptyCart')}
                    </Typography>
                    <br />
                    <Typography
                      variant="body2"
                      style={{ fontWeight: 'bold', color: 'grey' }}
                    >
                      {t('basket.goOnStockOrder')}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  container
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Grid item lg={12} style={{ width: mdDown ? '100%' : '30%' }}>
                    <Button
                      style={{
                        backgroundColor: environment.mainColor,
                        color: 'white',
                        width: mdDown ? '100%' : '30%',
                        height: '40px',
                      }}
                      onClick={() => {
                        navigate('/menu');
                        handleCloseCartDialoge();
                      }}
                    >
                      {' '}
                      {t('basket.addItems')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            )}
          </div>

          <Divider style={{ border: '3px solid #C4C4C4' }} />
          {!!cartData && !!cartData?.length && cartData?.length > 0 && (
            <>
              {' '}
              <Box
                sx={{
                  width: '100%',
                  padding: '12px 2% 3 2%',
                }}
              >
                <Typography
                  component="a"
                  variant="h6"
                  sx={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    color: environment.mainColor,
                    fontWeight: 700,
                    display: 'flex',
                    marginTop: '10px',
                  }}
                >
                  {t('basket.couponAndOffers')}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  padding: '12px 2% 3px',
                }}
              >
                {!editCpn ? (
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      color: 'gray',
                    }}
                  >
                    <Typography>
                      {code || `${t('basket.setCoupon')}`}
                    </Typography>
                    <Button
                      style={{
                        backgroundColor: environment.mainColor,
                        color: 'white',
                        width: mdDown ? 130 : 200,
                        height: 30,
                      }}
                      onClick={() => {
                        setEditCpn(true);
                      }}
                    >
                      {t('basket.applyCoupon')}
                    </Button>
                  </Grid>
                ) : (
                  <TextField
                    id="outlined-basic"
                    label={t('basket.applyCoupon')}
                    variant="outlined"
                    style={{ width: '100%', background: '#ededed' }}
                    value={coupon}
                    onChange={(e) => {
                      setCoupon(e.target.value);
                      setCouponRes('');
                    }}
                    onFocus={() => {
                      setRest(false);
                    }}
                    InputProps={{
                      endAdornment: (
                        <div
                          sx={{
                            visibility: coupon ? 'visible' : 'hidden',
                            cursor: 'pointer',
                          }}
                          onClick={async () => {
                            ApplyCoupon();
                          }}
                        >
                          {
                            !reset ? (
                              <AskIcon style={{ cursor: 'pointer' }} />
                            ) : (
                              <Snipper />
                            )
                            // couponRes == 'Success' ? (
                            //   <DoneIcon color="success" />
                            // ) : (
                            //   <ClearIcon color="error" />
                            // )
                          }
                        </div>
                      ),
                    }}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  width: '100%',
                  margin: '0 10px 5px',
                }}
              >
                <p
                  style={{
                    alignSelf: 'flex-start',
                    textAlign: 'start',
                    fontWeight: '500',
                    fontSize: 15,
                    marginLeft: 10,
                    color: couponRes === 'Success' ? 'green' : 'red',
                  }}
                >
                  {couponRes}
                  {couponRes === `${t('basket.success')}` &&
                    `${t('basket.couponConsiderd')}`}
                </p>
              </Box>
              <Divider style={{ border: '3px solid #C4C4C4' }} />
              <Box
                sx={{
                  width: '100%',
                  padding: '12px 2%',
                }}
              >
                {' '}
                <Typography
                  component="a"
                  variant="h6"
                  color={environment.mainColor}
                  sx={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    color: environment.mainColor,
                    fontWeight: 700,
                    display: 'flex',
                  }}
                >
                  {t('basket.addANote')}
                </Typography>
                <TextField
                  id="standard-basic"
                  label={t('basket.addANote')}
                  variant="outlined"
                  multiline
                  rows={3}
                  value={note}
                  sx={{
                    '& label.Mui-focused': {
                      color: 'black',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'gray',
                      },
                    },
                  }}
                  style={{ width: '100%' }}
                  onChange={(e) => dispatch(UdpateNote(e.target.value))} //redux
                />
              </Box>
              <Divider />
            {  console.log(deliveryFee , 'deliveryFee')}
              <BillDetails
                type={type}
                data={data}
                total={total}
                addressStore={addressStore}
                store={store}
                isVerify={isVerify}
                dataStore={dataStore}
                discount={discount}
                deliveryFee={isNaN(deliveryFee) ? 0 : deliveryFee}
              />
              <Typography
                component="a"
                variant="body1"
                color={environment.mainColor}
                sx={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  color: environment.mainColor,
                  fontWeight: 700,
                  display: 'flex',
                  color: 'red',
                  marginTop: '10px',
                  justifyContent: 'center',
                }}
              >
                <br />
                {checkoutErroutofstock}
                <br />
                {checkoutErr}
                <br />
              </Typography>
              <CustomButton
                bg={environment.mainColor}
                onClick={() => {
                  setCouponRes('');
                  setEditCpn(false);
                  if (validateCheckoutData()) {
                    handleCheckout();
                    setCheckoutErroutofstock('');
                    setCheckoutErr('');
                  }
                }}
                buttonText={`${t('basket.procedToCheckout')}`}
                disabled={
                  apiError !== '' ||
                  checkoutErr !== '' ||
                  checkoutErroutofstock !== ''
                }
              />{' '}
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};
export default CartDrawer;
